import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../axiosInstance';
import { Alert, Form, Button } from 'react-bootstrap';

/* A user can register inserting email, first name, last name, password and confirmation password into this form.
Once the registration is done, the login form is displayed with the message "You are now registered and can log in!". */
const RegistrationForm = ({ setShowLogin, setSuccessMessageReg }) => {
    const { t } = useTranslation();
    //Register form fields
    const [emailReg, setEmailReg] = useState('');
    const [firstNameReg, setFirstNameReg] = useState('');
    const [lastNameReg, setLastNameReg] = useState('');
    const [passwordReg, setPasswordReg] = useState('');
    const [password2Reg, setPassword2Reg] = useState('');
    const [registerErrorsReg, setRegisterErrorsReg] = useState([]);
    
    const onSubmitRegister = (e) => {
        e.preventDefault();
        //Axios register request
        axios.post('/auth/register', {
            email: emailReg,
            firstName: firstNameReg,
            lastName: lastNameReg,
            password: passwordReg,
            password2: password2Reg,
        }).then((response) => {
            //Register success
            if(response.data.registerStatus === 'ok') {
                setRegisterErrorsReg([]); //Reset error msgs
                setSuccessMessageReg(t("index.signup.registration_success"))
                setShowLogin(true); //show login form
            }else{ //Register fails, set errors
                const errors = response.data.errors;
                setRegisterErrorsReg(errors);
                setSuccessMessageReg('');
            }
        }).catch((err) => {
            console.log(err);
            setRegisterErrorsReg(errors => [...errors, t("index.signup.registration_fail")]);
        });
    }

    useEffect(() => {
        var loader = function () {
            var s = document.createElement("script"), tag = document.getElementsByTagName("script")[0];
            s.src="https://cdn.iubenda.com/iubenda.js"; 
            tag.parentNode.insertBefore(s,tag);
        };
        if(window.addEventListener){
            window.addEventListener("load", loader, false);
        }else if(window.attachEvent){
            window.attachEvent("onload", loader);
        }else{
            window.onload = loader;
        }
    }, []);

    return (
        <Form className="index-form" onSubmit={onSubmitRegister}>
            <h2 className="text-white">
                <i className="fas fa-user-plus"></i> {t("index.signup.title")}
            </h2>
            {registerErrorsReg.map((error, index) => {
                return <Alert key={index} variant="warning">{error.msg}</Alert>;
            })}
            <div className="input-field">
                <i className="fas fa-envelope"></i>
                <Form.Control required type="email" placeholder={t("index.signup.email_placeholder")} onChange={(e) => setEmailReg(e.target.value)}/>
            </div>
            <div className="input-field">
                <i className="fas fa-user"></i>
                <Form.Control required type="text" placeholder={t("index.signup.first_name_placeholder")} onChange={(e) => setFirstNameReg(e.target.value)} />
            </div>
            <div className="input-field">
                <i className="fas fa-user"></i>
                <Form.Control required type="text" placeholder={t("index.signup.last_name_placeholder")} onChange={(e) => setLastNameReg(e.target.value)} />
            </div>
            <div className="input-field">
                <i className="fas fa-lock"></i>
                <Form.Control required type="password" placeholder={t("index.signup.password_placeholder")} onChange={(e) => setPasswordReg(e.target.value)} />
            </div>
            <div className="input-field">
                <i className="fas fa-lock"></i>
                <Form.Control required type="password" placeholder={t("index.signup.confirm_password_placeholder")} onChange={(e) => setPassword2Reg(e.target.value)} />
            </div>
            <div className="form-group">
                <input required type="checkbox" className="form-check-input"/>
                <label className="form-check-label text-white">{t("index.signup.accept")}<a href="https://www.iubenda.com/privacy-policy/51544544" className="text-white">{t("index.signup.privacy_policy")}</a></label>
            </div>
            <Button type="submit" variant="light" className="border border-primary rounded font-weight-bold text-primary">
                {t("index.signup.register")}
            </Button>
            <h5 className="my-2 text-white">{t("index.login.question")}</h5>
            <Button variant="light" className="border border-primary rounded font-weight-bold text-primary" onClick={() => setShowLogin(true)}>
                {t("index.login.answer")}
            </Button>
        </Form>
    );
};

export default RegistrationForm;
