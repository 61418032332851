import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import YouTubePlayer from './YouTubePlayer';
import './Video.css';

/* It shows the video to the instructor in a resposive way. */
const VideoCard = () => {
    
    const { currentLecture } = useContext(ProfileContext);

    return (
        <Card bg="light" border="light" className="video-card">
            <Card.Body>
                <div className="card-video-container">
                    <YouTubePlayer videoUrl={currentLecture.youtubeVideoURL}/>
                </div>
            </Card.Body>
        </Card>
    )
}

export default VideoCard
