import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Badge, Button, Form } from 'react-bootstrap';
import { VideoContext } from '../../context/VideoContext';
import { ProfileContext } from '../../context/ProfileContext';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';
import Answer from './Answer';
import './Chat.css';

/* Modal that contain the detail of the question. It shows the question's answers and a form to insert
a new answer.*/
const QuestionModal = ({chatMessage, showQuestionModal, setShowQuestionModal}) => {
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const { socket } = useContext(SocketContext);
    const { player } = useContext(VideoContext);
    const [answerText, setAnswerText] = useState('');
    const { t } = useTranslation();

    const seekVideo = () => {
        player.seekTo(chatMessage.videoSeconds, true);
    }

    const solveComment = () => {
        if(chatMessage.status === 0) {
            socket.emit('solveQuestion', { 
                messageUUID: chatMessage.messageUUID,
                room: currentLecture.lectureUUID
            });
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        
        if(answerText.length > 0) {
            socket.emit('answer', { 
                answerText: answerText,
                messageUUID: chatMessage.messageUUID,
                user: currentUser,
                room: currentLecture.lectureUUID
            });

            const form = e.target.closest('form');
            form.reset();
        }
    }

    return (
        <Modal size="lg" show={showQuestionModal} onHide={() => setShowQuestionModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t("chat.question")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="box-header with-border">
                    <div className="user-block">
                        <span className="username">{chatMessage.sender.firstName + " " +chatMessage.sender.lastName}</span>
                        <span className="description">{t("chat.updated", { date: new Date(chatMessage.updatedAt) })}</span>
                        {chatMessage.status === 0 && chatMessage.sender.email === currentUser.email && 
                        <Button className="float-right mr-3" size="sm" variant="success" onClick={solveComment}><i className="fas fa-check"></i></Button>}
                    </div>
                </div>
                <div className="box-body">
                    <p>{chatMessage.text}</p>
                    <Badge bg="info" onClick={seekVideo} className="mx-1"><i className="fas fa-tags"> VIDEO</i></Badge> 
                    {chatMessage.status === 0 ? 
                    <Badge bg="warning" className="mx-1"><i className="fas fa-times"> UNSOLVED</i></Badge> 
                    :
                    <Badge bg="success" className="mx-1"><i className="fas fa-check"> SOLVED</i></Badge>}                    <span className="float-right text-muted">{`${chatMessage.answers.length} answers`}</span>
                </div>
                <div className="box-footer box-comments">
                    {chatMessage.answers.map(answer => (
                        <Answer key={answer.answerUUID} answer={answer} />
                    ))}
                </div>
                <div className="box-footer">
                    <Form id="answer-form" onSubmit={onSubmit} className="d-flex flex-row">
                        <Form.Control type="text" placeholder={t("chat.answer_placeholder")} maxLength={999}  onChange={e => setAnswerText(e.target.value)} /> 
                        <Button variant="primary" type="submit"><i className="fas fa-paper-plane"></i></Button>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default QuestionModal
