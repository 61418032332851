import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';
import Note from './Note';
import "./Notes.css";

/* It shows either a text editor to insert a new note, that can be private or shared, or the notes lists.
If there is a presentation it shows the note tagged to the current slide, else it shows all the notes. 
We use the ckeditor library to embed a rich text editor.*/
const TextEditor = ({ pageNumber, setNewNotesNumber, notesDisabled }) => {
    const [text, setText] = useState('');
    const [notesList, setNotesList] = useState([]);
    const [showEditor, setShowEditor] = useState(false);
    const { t } = useTranslation();

    const { socket } = useContext(SocketContext);
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    
    const handleNewNote = useCallback((newNote) => {
        //Push into notes list
        setNotesList(notesList => [...notesList, newNote]);
        //Increment new notes number
        setNewNotesNumber(notesNumber => notesNumber + 1);
    }, [setNewNotesNumber]);

    const handleNoteResume = useCallback((notesResume) => {
        //Substitute notes list
        setNotesList(notesResume);
        //Set notes number
        setNewNotesNumber(notesResume.length);
    }, [setNewNotesNumber]);

    const handleNoteUpdate = useCallback(note => {
        setNotesList(oldNotesList => 
            oldNotesList.map(n => 
                n.noteUUID === note.noteUUID
                ? note
                : n)
        );
    }, []);

    useEffect(() => {
        socket.on('newNote', (newNote) => handleNewNote(newNote));

        socket.on('notesResume', (notesResume) => handleNoteResume(notesResume));

        socket.on('noteUpdate', (note) => handleNoteUpdate(note));

        return () => {
            setNotesList([]);

            socket.off('newNote', (newNote) => handleNewNote(newNote));

            socket.off('notesResume', (notesResume) => handleNoteResume(notesResume));

            socket.off('noteUpdate', (note) => handleNoteUpdate(note));
        }
    }, [socket, handleNewNote, handleNoteUpdate, handleNoteResume]);

    const onKeepPrivate = () => {
        const page = (currentLecture.presentationPath == null ? null : pageNumber);
        socket.emit('privateNote', {
            note: text,
            slideNumber: page,
            user: currentUser,
            room: currentLecture.lectureUUID
        });
        
        setText('');
        setShowEditor(false);
    }

    const onShareNote = () => {
        const page = (currentLecture.presentationPath == null ? null : pageNumber);
        socket.emit('sharedNote', {
            note: text,
            slideNumber: page,
            user: currentUser,
            room: currentLecture.lectureUUID
        });

        setText('');
        setShowEditor(false);
    }

    return (
        <>
            {notesDisabled ?
            <div className="h-100">
                <div className="disabled-card"></div>
                <div className="disabled-card-text">
                    <h5>{t("dashboard.notes_disabled")}</h5>
                </div>
            </div>
            :
            <>        
                {showEditor ? 
                <div className="d-flex flex-column h-100">
                    <div className="editor">
                        <CKEditor
                        editor={ClassicEditor}
                        data={text}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setText(data);
                        }} 
                        />
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        <Button className="mr-1" variant="primary" onClick={onKeepPrivate}>
                            {t("notes.keep_private")}
                        </Button>
                        <Button variant="primary mr-1" onClick={onShareNote}>
                            {t("notes.share")}
                        </Button>
                        <Button variant="info" onClick={() => setShowEditor(false)}>
                            {t("notes.back")}
                        </Button>
                    </div>
                </div>
                :
                <div className="h-100 d-flex flex-row">
                    <div className="mr-2">
                        <Button onClick={() => setShowEditor(true)} variant="primary"
                        data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.create_note")}>
                            <i className="fas fa-notes-medical"></i>
                        </Button>
                    </div>
                    {currentLecture.presentationPath ?
                    <div className="notes-list d-flex flex-row w-100">
                        {notesList.filter(n => n.private && n.pageNumber === pageNumber).map((note, index) => (
                            <Note key={index} note={note} />
                        ))}
                        {notesList.filter(n => !n.private && n.pageNumber === pageNumber).map((note, index) => (
                            <Note key={index} note={note} />
                        ))}
                    </div>
                    : 
                    <div className="notes-list d-flex flex-row w-100">
                        {notesList.map((note, index) => (
                            <Note key={index} note={note} />
                        ))}
                    </div>}
                </div>
                }
            </>}
        </>
    )
}

export default TextEditor
