import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { ProfileContext } from '../../context/ProfileContext';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';
import { VideoContext } from '../../context/VideoContext';
import understoodImg from '../../assets/images/slightly-smiling-face_1f642.png';
import confusedImg from '../../assets/images/thinking-face_1f914.png';

/* It contains a slider that permits to the user to inser understanding reaction from a value of 0
 to a value of 2. */
const UnderstandingSwitch = () => {
    
    const [understanding, setUnderstanding] = useState(2);
    const { socket } = useContext(SocketContext);
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const { player } = useContext(VideoContext);
    const [label, setLabel] = useState(''); 
    const { t } = useTranslation();

    const onChange = (e) => {
        if(player.getPlayerState() === 1) {
            setUnderstanding(e.target.value);
            const videoSeconds = parseInt(player.getCurrentTime());
            socket.emit('understanding', {
                room: currentLecture.lectureUUID,
                understanding: e.target.value,
                videoSeconds: videoSeconds,
                user: currentUser
            });
        }
    }

    const onMouseOver = (e) => {
        if(player.getPlayerState() === 1) setLabel(e.target.value);
        else setLabel("Player must play");
    }

    return (
        <div className="d-inline-flex flex-row">
            <img className="mr-2" width="35px" height="35px" src={confusedImg} alt={t("feedback.not_clear")}
            data-bs-toggle="tooltip" data-bs-placement="top" title={t("feedback.not_clear")}/>
            <RangeSlider
                value={understanding} 
                min={0} max={2} size="lg" 
                onChange={e => onChange(e)}
                tooltipLabel={() => label }
                onMouseOver={onMouseOver}
            />
            <img className="ml-2" width="35px" height="35px" src={understoodImg} alt={t("feedback.clear")}
            data-bs-toggle="tooltip" data-bs-placement="top" title={t("feedback.clear")}/>
        </div>
    )
}

export default UnderstandingSwitch
