import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../context/ProfileContext';
import { Button, Row, Col } from 'react-bootstrap';
import CreateCourseForm from '../Courses/CreateCourseForm';
import TaughtCourseCard from './TaughtCourseCard';
import './Courses.css';

/* It contains a form to create a new course by inserting its name and the list of courses taught by the user. */
const TeacherCoursesList = ({setShowCourses}) => {
    const { courses, getCourses } = useContext(ProfileContext);
    const { t } = useTranslation();

    const [showCreateCourse, setShowCreateCourse ] = useState(false);

    useEffect(() => {
        getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
            {courses &&
            (<><Row>
                <Col md={12} className="d-flex flex-row align-items-baseline justify-content-between">
                    <h5 className="total">{t("home.courses.totalcourses", { count: courses.length })}</h5> 
                    <Button variant="success" className="float-right" onClick={() => setShowCreateCourse(true)}>
                        <i className="fas fa-folder-plus"></i> {t("home.courses.new_course")} 
                    </Button>
                </Col>
            </Row>
            {showCreateCourse && <CreateCourseForm setShowCreateCourse={setShowCreateCourse}/>}
            <br/>
            <Row className="justify-content-center">
                {courses.map(course => 
                    <TaughtCourseCard key={course.courseUUID} course={course} setShowCourses={setShowCourses} />
                )}
            </Row></>)}
        </>
        
    )
}

export default TeacherCoursesList
