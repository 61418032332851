import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../context/ProfileContext';
import { Table, Row, Col, Alert } from 'react-bootstrap';
import StudentLectureTableRow from './StudentLectureTableRow';
import { ToastContainer } from 'react-toastify';
import tableSort from "table-sort-js/table-sort.js";
import './Lectures.css';

/* Sortable table that shows the list of lecture of the selected course. */
const StudentLectureTable = () => {
    const { followedLectures, currentFollowedCourse } = useContext(ProfileContext);
    const { t } = useTranslation();

    useEffect(() => {
        tableSort();
    }, []);

    return (
        <Row className="justify-content-center">
            { currentFollowedCourse && followedLectures.length > 0 ?
            <Col lg={12} sm={12}>
                <div className="table-responsive">
                    <Table className="user-list table-sort">
                        <thead>
                            <tr className="text-center">
                                <th><span>{t("lectures.lecture")}</span></th>
                                <th><span>{t("lectures.day")}</span></th>
                                <th><span>{t("lectures.timetable")}</span></th>
                                <th><span>{t("lectures.status")}</span></th>
                                <th><span>{t("lectures.actions")}</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {followedLectures.map((lecture) => 
                                <StudentLectureTableRow key={lecture.lectureUUID} lecture={lecture}/>
                            )}
                        </tbody>
                    </Table>
                    <ToastContainer />
                </div>
            </Col>
            :
            <Col lg={6} sm={12} className="mt-4">
                <Alert variant="warning" className="text-center">
                    <Alert.Heading>{t("lectures.attention")}</Alert.Heading>
                    <p>
                    {t("lectures.no_lectures")}
                    </p>
                </Alert>
            </Col>}
        </Row>
    )
}

export default StudentLectureTable
