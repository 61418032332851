import React, { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ProfileModal from "../Auth/ProfileModal";
import i18next from "i18next";

/* Application navbar. It contains the links to the current accessible pages, such as about page, home page, index page and
also a logout button if the user is logged.*/
const AppNavbar = () => {
  const { currentUser, logoutUser } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [showProfileModal, setShowProfileModal] = useState(false);

  const onLogout = () => {
    axios.get("auth/logout").then((response) => {
      if (response.data.logoutStatus === "ok") {
        logoutUser();
        history.push("/");
      }
    });
  };

  return (
    <Navbar expand="lg" className="navbar navbar-sm" variant="dark">
      <Link to="/">
        <Navbar.Brand>{t("navbar.brand")}</Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
            {currentUser && (
            <Link className="nav-item nav-link" to="/home">
                {t("navbar.home")}
            </Link>
            )}
            <Link className="nav-item nav-link" to="/about">
                {t("navbar.about")}
            </Link>
            <Link className="nav-item nav-link" to="/mooc">
                {t("navbar.moocs")}
            </Link>
        </Nav>
        <Nav>
            <NavDropdown title={t("navbar.language")}>
              <NavDropdown.Item onClick={() => i18next.changeLanguage('it')}>Italiano</NavDropdown.Item>
              <NavDropdown.Item onClick={() => i18next.changeLanguage('en')}>English</NavDropdown.Item>
            </NavDropdown>
            {currentUser ? (
            <>
                <Link className="nav-item nav-link" to="#" onClick={() => setShowProfileModal(true)}>
                    <i className="fas fa-user"></i>{" "}
                    {currentUser.firstName + " " + currentUser.lastName}
                </Link>
                <ProfileModal showModal={showProfileModal} setShowModal={setShowProfileModal} />
                <Link className="nav-item nav-link" to="#" onClick={onLogout}>
                  {t("navbar.logout")}
                </Link>
            </>
            ) : (
            <Link className="nav-item nav-link" to="/">
                {t("navbar.login")}
            </Link>
            )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
