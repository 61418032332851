import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';
import { VideoContext } from '../../context/VideoContext';
import QuestionModal from '../Chat/QuestionModal';
import './Chat.css';

/* Chat messages that display differently in case the message is a normal message or a question.
If the message is a question it contains a clickable badge to see the answer and a button to solve it.
In both cases there are the like and dislike reactions. */
const ChatMessage = ({chatMessage}) => {
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const { socket } = useContext(SocketContext);
    const { player } = useContext(VideoContext);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const { t } = useTranslation();

    const voteSum = chatMessage.reactions.filter(r => r.value === 1).length - chatMessage.reactions.filter(r => r.value === 0).length; 
    const myReaction = chatMessage.reactions.filter(r => r.userEmail === currentUser.email)[0];
    const upClass = myReaction && myReaction.value === 1 ? 'fas' : 'far';
    const downClass = myReaction && myReaction.value === 0 ? 'fas' : 'far';
    
    const onVote = (value) => { 
        socket.emit('reaction', {
            value: value,
            messageUUID: chatMessage.messageUUID,
            user: currentUser,
            room: currentLecture.lectureUUID
        });
    }

    const seekVideo = () => {
        player.seekTo(chatMessage.videoSeconds, true);
    }

    const solveComment = () => {
        if(chatMessage.status === 0) {
            socket.emit('solveQuestion', { 
                messageUUID: chatMessage.messageUUID,
                room: currentLecture.lectureUUID
            });
        }
    }

    return (
        <>
            {chatMessage.videoSeconds !== null ? 
            <div className="question">
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <p className="meta">{chatMessage.sender.firstName + " " +chatMessage.sender.lastName} <span className="time">{t("chat.updated", { date: new Date(chatMessage.updatedAt) })}</span>
                        <Badge bg="info" onClick={seekVideo} className="mx-1"><i className="fas fa-tags"> {t("chat.video")}</i></Badge> 
                        {chatMessage.status === 0 ? 
                        <Badge bg="warning" className="mx-1"><i className="fas fa-times"> {t("chat.unsolved")}</i></Badge> 
                        :
                        <Badge bg="success" className="mx-1"><i className="fas fa-check"> {t("chat.solved")}</i></Badge>}
                        <Badge bg="primary" className="mx-1" onClick={() => setShowQuestionModal(true)}><i className="fas fa-list-ul"> {chatMessage.answers.length} {t("chat.answers")}</i></Badge>
                        </p>
                        <QuestionModal chatMessage={chatMessage} showQuestionModal={showQuestionModal} setShowQuestionModal={setShowQuestionModal} />
                    </div>
                    {chatMessage.status === 0 && chatMessage.sender.email === currentUser.email && 
                    <Button className="ml-auto mr-3" size="sm" variant="success" onClick={solveComment}><i className="fas fa-check"></i></Button>}
                    <div className="d-flex flex-row align-items-baseline">
                        <i className={`${upClass} fa-thumbs-up`} onClick={() => onVote(1)}></i>
                        <p className="mx-1">{voteSum}</p>
                        <i className={`${downClass} fa-thumbs-down `} onClick={() => onVote(0)}></i>
                    </div>
                </div>
                <p className="text">{chatMessage.text}</p>
                <div className="d-flex justify-content-center">
                <Button size="sm" variant="success" onClick={() => setShowQuestionModal(true)}> {t("chat.answer")} </Button>
                </div>
            </div>
            :
            <div className="message">
                <div className="d-flex flex-row justify-content-between">
                    <p className="meta">{chatMessage.sender.firstName + " " +chatMessage.sender.lastName} <span>{t("chat.updated", { date: new Date(chatMessage.updatedAt) })}</span></p>
                    <div className="d-flex flex-row align-items-baseline">
                        <i className={`${upClass} fa-thumbs-up`} onClick={() => onVote(1)}></i>
                        <p className="mx-1">{voteSum}</p>
                        <i className={`${downClass} fa-thumbs-down `} onClick={() => onVote(0)}></i>
                    </div>
                </div>
                <p>{chatMessage.text}</p>
            </div>
            }
        </>
    )
}

export default ChatMessage
