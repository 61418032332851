import { HashRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import IndexPage from "./pages/IndexPage";
import AboutPage from "./pages/AboutPage";
import ErrorPage from "./pages/ErrorPage";
import NotFound from "./pages/NotFound";
import InstructorLecturePage from "./pages/InstructorLecturePage";
import LoggedRoute from "./routes/LoggedRoute";
import { ProfileProvider } from "./context/ProfileContext";
import { VideoProvider } from "./context/VideoContext";
import { AuthProvider } from "./context/AuthContext";
import { SocketProvider } from "./context/SocketContext";
import StudentLecturePage from "./pages/StudentLecturePage";
import InjectAxiosInterceptors from "./InjectAxiosInterceptors";
import ReportPage from "./pages/ReportPage";
import MoocPage from "./pages/MoocPage";
import ReportInstructorPage from "./pages/ReportInstructorPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootswatch/dist/lumen/bootstrap.min.css";

function App() {
  return (
    <AuthProvider>
      <ProfileProvider>
        <VideoProvider>
          <SocketProvider>
            <Router>
              <InjectAxiosInterceptors />
              <Switch>
                <Route path="/" exact component={IndexPage} />
                <Route path="/about" exact component={AboutPage} />
                <Route path="/mooc" exact component={MoocPage} />
                <LoggedRoute path="/home" component={HomePage} />
                <LoggedRoute
                  path="/lecture"
                  component={InstructorLecturePage}
                />
                <LoggedRoute
                  path="/studentLecture"
                  component={StudentLecturePage}
                />
                <LoggedRoute path="/report" component={ReportPage} />
                <LoggedRoute
                  path="/instructorReport"
                  component={ReportInstructorPage}
                />
                <Route path="/error" component={ErrorPage} />
                <Route component={NotFound} />
              </Switch>
            </Router>
            <ToastContainer />
          </SocketProvider>
        </VideoProvider>
      </ProfileProvider>
    </AuthProvider>
  );
}

export default App;
