/* eslint-disable import/no-anonymous-default-export */
export default (state, action) => {
    switch (action.type) {
        case 'LOGIN_USER':
            return {
                ...state,
                currentUser: action.payload,
            }
        case 'LOGOUT_USER':
            return {
                ...state,
                currentUser: null
            }
        default: 
            return state;
    }
}