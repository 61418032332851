import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import VideoCard from '../components/Video/VideoCard';
import ReportPresentationDashboard from '../components/Dashboards/ReportDashboards/ReportPresentationDashboard';
import AppNavbar from '../components/Navbars/AppNavbar';
import ReportCommunicationDashboard from '../components/Dashboards/ReportDashboards/ReportCommunicationDashboard';
import ReportFeedbackDashboard from '../components/Dashboards/ReportDashboards/ReportFeedbackDashboard';
import { ProfileContext } from '../context/ProfileContext';
import { SocketContext } from '../context/SocketContext';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './Pages.css';

const ReportPage = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const { socket, connectSocket } = useContext(SocketContext);
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const history = useHistory();
    const { t } = useTranslation();
    const attempts = useRef(0); //Keep track of the number of reconnection attempts

    /* Connect to socket server, if cannot connect then redirect to the home page with an error message */
    useEffect(() => {
        if(!socket.connected) {
            connectSocket(socket);

            socket.on('connect', () => {
                if(attempts.current > 0) {
                    toast.success(t("toast.socket_reconnected"), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                socket.emit('report', { 
                    room: currentLecture.lectureUUID,
                    user: currentUser
                });
                attempts.current = 0;
            })

            //Connection error
            socket.on("connect_error", (err) => {
                //For three time try to reconnect
                if(attempts.current < 5) {
                    toast.error(t("toast.socket_error", { attempt: attempts.current + 1 }), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    attempts.current++;
                }else{ //Redirect to home page
                    toast.error(t("toast.socket_fail", { attempt: attempts.current + 1 }), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    history.push('/home'); //redirect
                }
            });  
        }

        return () => { //Disconnect socket when leave page
            socket.off("connect");
            socket.off("connect_error");

            if(socket.connected) {
                socket.disconnect();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    return (
        <Container fluid className="banner">
            <Row>
                <AppNavbar />
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <VideoCard />
                </Col>
                <Col lg={6} sm={12}>
                    <ReportPresentationDashboard pageNumber={pageNumber} setPageNumber={setPageNumber} />         
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <ReportFeedbackDashboard />
                </Col>
                <Col lg={6} sm={12}>
                    <ReportCommunicationDashboard pageNumber={pageNumber} />
                </Col>
            </Row>
        </Container>
    )
}

export default ReportPage
