import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Row, Col, Container, Spinner } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import YouTubePlayer from '../Video/YouTubePlayer';
import Switch from 'react-switch';
import moment from 'moment';
import getVideoId from 'get-video-id';
import axios from '../../axiosInstance';
import './Lectures.css';


/* Modal that permits to create a new lecture. It requires the lecture name, the scheduled start time,
the scheduled end time, the YouTube video url and the presentation path.*/
const CreateLectureModal = ({ openLectureModal, setOpenLectureModal }) => {
    const [name, setName] = useState('');
    const [scheduledStartTime, setScheduledStartTime] = useState(moment().format("YYYY-MM-DDTHH:mm"));
    const [scheduledEndTime, setScheduledEndTime] = useState('');
    const [youtubeVideoURL, setYouTubeVideoURL] = useState('');
    const [showPlayer, setShowPlayer] = useState(false);
    const [mooc, setMooc] = useState(true);
    const [fileData, setFileData] = useState(null);
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [loading, setLoading] = useState(false);

    const { currentCourse, createLecture } = useContext(ProfileContext);
    const { t } = useTranslation();

    const loginMethod = localStorage.getItem('loginMethod');

    const fetchLiveUrl = () => {
        setLoading(true);
        axios.get('/lectures/liveBroadcasts')
            .then(res => res.data)
            .then(video => {
                if(video) {
                    const videoUrl = `https://youtu.be/${video.id}`;
                    setYouTubeVideoURL(videoUrl);
                    setShowPlayer(true);
                }
                setLoading(false);
            });
    }

    /* const createLiveStreaming = () => {
        axios.get('/lectures/goLive')
            .then(res => res.data)
            .then(data => console.log(data));
    } */

    useEffect(() => {
        if(loginMethod === 'google' && openLectureModal) {
            axios.get('/lectures/channels')
                .then(res => res.data)
                .then(channels => {
                    if(channels && channels.length > 0) {
                        setChannels(channels);
                        setSelectedChannel(channels[0]);
                    }
                });
        }
    }, [openLectureModal, loginMethod]);

    const onSubmit = (e) => {
        e.preventDefault();
        const courseUUID = currentCourse.courseUUID;
        //Get video id
        const videoId = getVideoId(youtubeVideoURL).id;  
        //Send the request to create a new lecture
        createLecture({name, scheduledStartTime, scheduledEndTime, courseUUID, youtubeVideoURL: videoId, fileData, mooc});
        //Reset the form
        const form = document.getElementById('createLectureForm');
        form.reset();
        //Close the modal
        setOpenLectureModal(false);
    }

    return (
        <Modal show={openLectureModal} onHide={() => setOpenLectureModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t("lectures.create")}</Modal.Title>
            </Modal.Header>

            <Container>
                <Row>
                    <Col>
                        <Form id="createLectureForm" onSubmit={onSubmit}>
                            <Modal.Body>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.name")}</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter name" maxLength={99} onChange={(e) => setName(e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.start_time")}</Form.Label>
                                    <Form.Control required type="datetime-local" min={moment().format("YYYY-MM-DDTHH:mm")} defaultValue={moment().format("YYYY-MM-DDTHH:mm")} onChange={(e) => setScheduledStartTime(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.end_time")}</Form.Label>
                                    <Form.Control required type="datetime-local" min={scheduledStartTime} onChange={(e) => setScheduledEndTime(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))}/>
                                </Form.Group>
                                {loginMethod === 'google' && <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.channel")}</Form.Label>
                                    <Form.Select defaultValue={channels[0]?.channelId} onChange={(e) => setSelectedChannel(e.target.value)}>
                                        {channels.map((channel) => 
                                        (<option key={channel.channelId} value={channel.channelId}>{channel.name}</option>))}
                                    </Form.Select>
                                </Form.Group>}
                                <div className="d-flex justify-content-center">
                                    <a href={`https://studio.youtube.com/channel/${selectedChannel}/livestreaming/webcam`} target="_blank" rel="noreferrer">
                                        <Button variant="danger" className="mb-2 mr-3">
                                            <i className="fab fa-youtube"></i> {t("lectures.start_live")}
                                        </Button>
                                    </a>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    {loginMethod === 'google' && <Button variant="success" className="mr-3" onClick={fetchLiveUrl}>
                                        {loading ? <Spinner animation="border" variant="light" /> : t("lectures.load_video")}
                                    </Button>}
                                    <Form.Group className="mb-3 w-100">
                                        <Form.Label>{t("lectures.video_url")}</Form.Label>
                                        <Form.Control type="text" maxLength={499} value={youtubeVideoURL} onChange={(e) => setYouTubeVideoURL(e.target.value)}/>
                                    </Form.Group>
                                </div>
                                <div className="d-flex justify-content-center mb-3">
                                    <Button variant="primary" onClick={() => setShowPlayer(!showPlayer)}>
                                        <i className="fas fa-video"></i> {showPlayer ? t("lectures.hide_video") : t("lectures.test_video") }
                                    </Button>
                                </div>
                                {showPlayer && <div className="video-container px-5 mb-3">
                                    <YouTubePlayer videoUrl={getVideoId(youtubeVideoURL).id}/>
                                </div>}
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.presentation")}</Form.Label>
                                    <Form.Control type="file" accept="application/pdf" onChange={(e) => setFileData(e.target.files[0])}/>
                                </Form.Group>
                                <Form.Group className="d-flex flex-row justify-content-start mb-3">
                                    <Form.Label>{t("lectures.mooc")}</Form.Label>
                                    <Switch onChange={value => setMooc(value)} checked={mooc} className="react-switch ml-2" />
                                </Form.Group>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="primary" type="submit">
                                    {t("lectures.submit")}
                                </Button>
                                <Button variant="secondary" onClick={() => setOpenLectureModal(false)}>
                                    {t("lectures.close")}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Modal>
    )
}

export default CreateLectureModal
