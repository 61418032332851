import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Row } from 'react-bootstrap';
import UserCard from './UserCard';
import { SocketContext } from '../../context/SocketContext';
import "./Participants.css";

/* Box that contain the list of participants. */
const ParticipantsBox = ({ setParticipantsNumber }) => {
    const { socket } = useContext(SocketContext);
    const [lectureParticipants, setLectureParticipants] = useState([]);
    const [instructor, setInstructor] = useState(null);
    
    const handleParticipants = useCallback((participants, instructor) => {
        setLectureParticipants(participants);
        setInstructor(instructor);
        let number = participants.length;
        if(instructor) number++;
        setParticipantsNumber(number);
    }, [setParticipantsNumber]);

    useEffect(() => {
        socket.on('participants', ({participants, instructor}) => handleParticipants(participants, instructor));
    
        return () => { 
            setLectureParticipants([]);
            socket.off('participants', ({participants, instructor}) => handleParticipants(participants, instructor));
        }
    }, [socket, handleParticipants]);


    return (
        <Row className="participants-box">
            {instructor && <UserCard user={instructor} instructor={true} />}
            {lectureParticipants.map(participant => 
                <UserCard key={participant.email} user={participant} />)}    
        </Row>          
    )
}

export default ParticipantsBox
