import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Row, Col } from 'react-bootstrap';
import AppNavbar from '../components/Navbars/AppNavbar';
import { Link } from 'react-router-dom';
import './Pages.css';

const ErrorPage = ( ) => {
    const { t } = useTranslation();

    return (
        <Container fluid className="banner">
            <Row>
                <Col md={12}>
                    <AppNavbar />
                </Col>
                <Col md={12} className="info">
                    <h1 className="text-center mb-3">{t("error.not_found")}</h1>
                    <p className="text-center">
                        {t("error.not_found")}
                    </p>
                    <Link to="/">
                        <Button variant="light" className="text-center" size="md">
                            {t("error.back")}
                        </Button>
                    </Link>
                </Col>
                
                 
            </Row>
        </Container>
    )
}

export default ErrorPage