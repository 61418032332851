import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, Card, Badge } from 'react-bootstrap';
import ChatBox from '../../Chat/ChatBox';
import ParticipantsBox from '../../Participants/ParticipantsBox';
import TextEditor from '../../Notes/TextEditor';
import { SocketContext } from '../../../context/SocketContext';
import { ProfileContext } from '../../../context/ProfileContext';
import '../Dashboards.css';

/* Dashboard showing the chat, the notes and the participants. It is shown when there is a presentation pdf associated to the lecture. */
const CommunicationDashboard = ({ pageNumber }) => {
    const { socket } = useContext(SocketContext);
    const { currentLecture } = useContext(ProfileContext);
    const { t } = useTranslation();

    const [chatDisabled, setChatDisabled] = useState(false);
    const [notesDisabled, setNotesDisabled] = useState(false);
    const [participantsNumber, setParticipantsNumber] = useState(0);
    const [newMessagesNumber, setNewMessagesNumber] = useState(0);
    const [newNotesNumber, setNewNotesNumber] = useState(0);

    const handleEvent = useCallback(({chatActive, notesActive}) => {
        setChatDisabled(!chatActive);
        setNotesDisabled(!notesActive);
    }, []);
    
    useEffect(() => {
        socket.on("newFunctionalityStatus", (status) => {
            handleEvent(status);
        });

        return () => {
            socket.off("newFunctionalityStatus", (status) => {
                handleEvent(status);
            });
        }
    }, [socket, handleEvent]);

    return (
        <Card bg="light" border="light" className="communication-card">
            <Card.Header className="p-0">
                <Nav className={`d-flex flex-md-row justify-content-between tab-title ${currentLecture.presentationPath ? 'triple' : 'double'}`}>
                    <div data-toggle="tab" data-target="#chatTab" className="active" onClick={() => setNewMessagesNumber(0)}> 
                        {t("dashboard.chat")} 
                        {newMessagesNumber > 0 && <Badge pill bg="success" className="ml-1">{newMessagesNumber}</Badge>}
                    </div>
                    <div data-toggle="tab" data-target="#editorTab" onClick={() => setNewNotesNumber(0)}> 
                        {t("dashboard.notes")}
                        {newNotesNumber > 0 && <Badge pill bg="primary" className="ml-1">{newNotesNumber}</Badge>}
                    </div>
                    {currentLecture.presentationPath &&
                    <div data-toggle="tab" data-target="#participantsTab"> 
                        {t("dashboard.participants")} <Badge pill bg="warning" className="ml-1">{participantsNumber}</Badge> 
                    </div>}
                </Nav>       
            </Card.Header>
            <Card.Body className="card-container">
                <div className="tab-content h-100">
                    <div className="tab-pane active h-100" id="chatTab">
                        <ChatBox setNewMessagesNumber={setNewMessagesNumber} chatDisabled={chatDisabled}/>
                    </div>
                    <div className="tab-pane h-100" id="editorTab">
                        <TextEditor pageNumber={pageNumber} setNewNotesNumber={setNewNotesNumber} notesDisabled={notesDisabled}/> 
                    </div>
                    {currentLecture.presentationPath &&
                    <div className="tab-pane h-100" id="participantsTab">
                        <ParticipantsBox setParticipantsNumber={setParticipantsNumber} />
                    </div>}
                </div>
            </Card.Body>
        </Card>
    )
}

export default CommunicationDashboard
