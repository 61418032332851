import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Badge } from 'react-bootstrap';
import moment from 'moment';
import UpdateLectureModal from './UpdateLectureModal';
import { Link } from 'react-router-dom';
import { ProfileContext } from '../../context/ProfileContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from '../../axiosInstance';
import FileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import './Lectures.css';

/* Table tow that shows the information about a lecture, such as the name, the timetable, the status and the
possible actions. The actions are: delete the lecture, update the lecture, go to live lecture, download the slides,
 download the notes, go to report.*/
const InstructorLectureTableRow = ({lecture}) => {
    
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const { selectLecture, deleteLecture } = useContext(ProfileContext);
    const { t } = useTranslation(); 

    const onDownload = () => {
        toast.info(t("lectures.download"), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        axios({
            url: "lectures/downloadPDF/" + lecture.lectureUUID,
            method: "GET",
            responseType: "blob",
        }).then(res => {
            if(res.data) {
                FileDownload(res.data, t("lectures.pdf_name", { name: lecture.name, datetime: new Date(moment())}));  
            }
        });
    }

    const getStatus = () => {
        const now = moment();
        if(now.isAfter(lecture.scheduledStartTime) && now.isBefore(lecture.scheduledEndTime)) {
            return { code: 1, status: t("lectures.live"), variant: "success"};
        }
        if(now.isAfter(lecture.scheduledEndTime)) {
            return { code: 2, status: t("lectures.ended"), variant: "warning" };
        }
        return { code: 3, status: t("lectures.scheduled"), variant: "info" };
    }

    const onDelete = () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: t("swal.title"),
            text: t("swal.delete_text"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("swal.confirm")
          }).then((result) => {
            if (result.isConfirmed) {
                deleteLecture(lecture.lectureUUID);
                MySwal.fire(
                    t("swal.deleted"),
                    t("swal.lecture_deleted"),
                    'success'
              )
            }
        })
    }

    /* const onDownloadNotes = () => {
        toast.info(t("lectures.download"), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
        axios({
            url: "lectures/downloadNotes/" + lecture.lectureUUID,
            method: "GET",
            responseType: "blob",
        }).then(res => {
            if(res.data?.err) {
                toast.error(t("lectures.impossible_download"), {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else{
                FileDownload(res.data, t("lectures.notes_name", {name: lecture.name}));
            }  
        })
    } */

    const onUpdate = () => {
        setShowUpdateModal(true);
    }
    
    return (
        <>
            <tr>
                <td className="text-center">
                    <strong>{lecture.name}</strong>
                </td>
                <td className="text-center">
                    {moment(lecture.scheduledStartTime).format('MMM Do YYYY')}
                </td>
                <td className="text-center">
                    {moment(lecture.scheduledStartTime).format('h:mm a') 
                    + " - " + 
                    moment(lecture.scheduledEndTime).format('h:mm a')}
                </td>
                <td className="text-center">
                    <Badge bg={getStatus().variant}>{getStatus().status}</Badge>
                </td>
                <td className="text-center">
                    {getStatus().code === 1 && <Link to="/lecture">
                        <Button variant="success" className="mr-1" onClick={() => selectLecture(lecture)}
                        data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.show_lecture")}>
                            <i className="fas fa-eye"></i>
                        </Button>
                    </Link>}
                    <Button variant="danger" className="mr-1" onClick={onDelete}
                    data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.delete_lecture")}>
                        <i className="fas fa-trash"></i>
                    </Button>
                    <Button variant="primary" className="mr-1" onClick={onUpdate}
                    data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.update_lecture")}>
                        <i className="fas fa-edit"></i>
                    </Button>
                    {lecture.presentationPath &&
                    <Button variant="primary" className="mr-1" onClick={onDownload}
                    data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.download_slides")}>
                        <i className="fas fa-file-download"></i>
                    </Button>}
                    {getStatus().code === 2 &&
                    <>
                        {/* <Button variant="info" className="mr-1" onClick={onDownloadNotes}
                        data-bs-toggle="tooltip" data-bs-placement="top" title={t("lectures.download_notes")}>
                            <i className="fas fa-book"></i>
                        </Button> */}
                        <Link to="/instructorReport">
                            <Button variant="warning" className="mr-1" onClick={() => selectLecture(lecture)}
                            data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.show_report")}>
                                <i className="far fa-file-alt"></i>
                            </Button>
                        </Link>
                    </>}
                </td>
            </tr>
            <UpdateLectureModal lecture={lecture} showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} />
        </>

    )
}

export default InstructorLectureTableRow
