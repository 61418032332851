/* eslint-disable import/no-anonymous-default-export */
export default (state, action) => {
    switch (action.type) {
        case 'CONNECT':
            return {
                ...state,
                socket: state.socket.connect(),
            }
        default: 
            return state;
    }
}