import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';
import { VideoContext } from '../../context/VideoContext';
import ChatMessage from './ChatMessage';
import './Chat.css';

/* Box containing the chat with the list of chat messages and a form to insert a new question or a new normal message. */
const ChatBox = ({ setNewMessagesNumber, chatDisabled }) => {
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const { socket } = useContext(SocketContext);
    const { player } = useContext(VideoContext);
    const { t } = useTranslation();

    const [message, setMessage] = useState('');
    const [messagesList, setMessagesList] = useState([]);

    const onSend = (e) => {
        e.preventDefault();

        if(message.length > 0) {
            socket.emit('chatMessage', {
                message: message,
                sender: currentUser,
                room: currentLecture.lectureUUID
            });

            const form = document.getElementById('chat-form');
            form.reset();

        }
    }

    const onQuestionSend = (e) => {
        e.preventDefault();
        
        if(message.length > 0) {
            const videoTime = parseInt(player.getCurrentTime());
            socket.emit('question', {
                question: message,
                user: currentUser,
                room: currentLecture.lectureUUID,
                videoTime: videoTime
            });
            
            const form = document.getElementById('chat-form');
            form.reset();
        }
    }

    const handleNewMessage = useCallback(message => {
        //Push message into messages list
        setMessagesList(messagesList => [...messagesList, message]);
        setNewMessagesNumber(n => n + 1); //Increment number of new messages

        const chatMessages = document.querySelector('.chat-messages');
        //Scroll down
        if(chatMessages) chatMessages.scrollTop = chatMessages.scrollHeight;
    }, [setNewMessagesNumber]); 
    
    const handleChatResume = useCallback((chatMessages) => {
        //Push messages into messages list
        setMessagesList(chatMessages);
        //Set messages number
        setNewMessagesNumber(chatMessages.length);
        //Scroll down
        const chatMessagesContainer = document.querySelector('.chat-messages');
        if(chatMessagesContainer) chatMessagesContainer.scrollTop = chatMessagesContainer.scroll;
    }, [setNewMessagesNumber]);
    
    const handleMessageUpdate = useCallback((chatMessage) => {
        setMessagesList( oldMessagesList => 
            oldMessagesList.map(message => 
                message.messageUUID === chatMessage.messageUUID
                ? chatMessage
                : message )
        );
    }, [])

    useEffect(() => {
        socket.on('newChatMessage', (chatMessage) => {
            handleNewMessage(chatMessage)
        });

        socket.on('chatResume', (chatMessages) => {
            handleChatResume(chatMessages);
        });

        socket.on('messageUpdate', (chatMessage) => {
            handleMessageUpdate(chatMessage);
        });

        return () => {
            setMessagesList([]);

            socket.off('newChatMessage', (chatMessage) =>
                handleNewMessage(chatMessage));
            
            socket.off('chatResume', (chatMessages) =>
                handleChatResume(chatMessages));
                
            socket.off('messageUpdate', (chatMessage) => {
                handleMessageUpdate(chatMessage);});
        }
    }, [socket, handleNewMessage, handleChatResume, handleMessageUpdate]);

    return (
        <>
            {chatDisabled ?
            <div className="h-100">
                <div className="disabled-card"></div>
                <div className="disabled-card-text">
                    <h5>{t("dashboard.chat_disabled")}</h5>
                </div>
            </div>
            :
            <div className="d-flex flex-column h-100">
                <div className="chat-messages">
                    {messagesList.map((chatMessage, index) => 
                        <ChatMessage key={chatMessage.messageUUID} chatMessage={chatMessage}/>)}
                </div>
                <Form id="chat-form" onSubmit={onSend} className="d-flex flex-row align-items-center">
                    <Form.Control id="msg" type="text" placeholder={t("chat.enter_message_placeholder")} autoComplete="off" maxLength={255} required onChange={(e) => setMessage(e.target.value)}/>
                    <Button variant="warning" onClick={onQuestionSend} 
                    data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.post_question")}>
                        <i className="fas fa-question"></i>
                    </Button>
                    <Button variant="primary" type="submit" data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.post_message")}>
                        <i className="fas fa-paper-plane"></i>
                    </Button>
                </Form>
            </div>}
        </>
    )
}

export default ChatBox
