import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table } from 'react-bootstrap';
import tableSort from "table-sort-js/table-sort.js";

/* Modal that show the list and the number of students enrolled to a given course. */
const EnrolledStudentsModal = ({ showModal, setShowModal, students}) => {
  
    const { t } = useTranslation();

    useEffect(() => {
        tableSort();
    }, []);

    return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>{t("home.courses.enrolled_students")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex justify-content-center">
                <h5 className="total">{t("home.courses.total", { count: students.length }) }</h5> 
            </div>
            <div className="table-responsive">
                <Table className="user-list table-sort">
                    <thead>
                        <tr className="text-center">
                            <th><span>{t("home.courses.email")}</span></th>
                            <th><span>{t("home.courses.first_name")}</span></th>
                            <th><span>{t("home.courses.last_name")}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student) => 
                            <tr className="text-center" key={student.email}>
                                <td>{student.email}</td>
                                <td>{student.firstName}</td>
                                <td>{student.lastName}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
                {t("home.courses.close")}
            </Button>
        </Modal.Footer>
    </Modal>
  );
};

export default EnrolledStudentsModal;
