import React, { useState, useEffect } from 'react';
import attentionImg from '../../assets/images/attention.png';
import './Feedbacks.css';

/* Animated warning shown when the understanding % goes under a fixed threeshold. */
const UnderstandingWarning = ({ understandingData }) => {
    const [showWarning, setShowWarning] = useState(false);
    const THRESHOLD = 50;

    useEffect(() => {
        const textRed = document.querySelector('.text-red');
        if(textRed) textRed.innerHTML = textRed.textContent.replace(/\S/g, "<span class=\"text-letter\"> $&</span>");

        const element = document.querySelectorAll(".text-letter");
        for(let i=0; i<element.length; i++) {
            element[i].style.transform = "rotate(" + i*15 + "deg)";
        }

        const percentage = Math.floor((understandingData[2] + 0.5 * understandingData[1]) / (understandingData[0] + understandingData[1] + understandingData[2]) * 100); 
        setShowWarning(percentage < THRESHOLD);

    }, [understandingData, showWarning]);

    return (
        <div className="signal-container">
            {showWarning &&
            <section className="signal">
                <h2 className="text text-red">Understanding-Low-Level-</h2>
                <img className="mb-3 big-small" src={attentionImg} width="95px" height="95px" alt="Attention" />
            </section>}
        </div>
    )
}

export default UnderstandingWarning
