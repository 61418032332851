import React, { useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { SocketContext } from '../../context/SocketContext';

/* Line chart that shows the participants' understanding % in the last 5 minutes. */
const LectureLineChart = ({ setFiveMinData, fiveMinData }) => {
    const INTERVAL_MS = 1000;
    var lastPercentage = fiveMinData[fiveMinData.length - 1];
    const { socket } = useContext(SocketContext);
    const { t } = useTranslation();

    const handleUnderstandingChange = useCallback((understanding) => {
       //Calculate percentage
       // eslint-disable-next-line react-hooks/exhaustive-deps
       lastPercentage = (Math.floor((understanding[2] + 0.5 * understanding[1]) / (understanding[0] + understanding[1] + understanding[2]) * 100)); 
    }, []);

    useEffect(() => {
      //Each INTERVAL_MS update the graph data setting the last value to the last percentage
      const interval = setInterval(() => {
        setFiveMinData(oldData => [...oldData, lastPercentage]);
        setFiveMinData(oldData => oldData.slice(- (oldData.length - 1)));
      }, INTERVAL_MS);

      socket.on('understandingChange', understanding => {
        handleUnderstandingChange(understanding);
      });

      return () => {
        clearInterval(interval);
        socket.off('understandingChange', understanding => {
          handleUnderstandingChange(understanding);
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleUnderstandingChange, setFiveMinData, socket]);
    
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );

    const options = {
        maintainAspectRatio: false,
        animation: false,
        scales: {
          y: {
            max: 100,
            min: 0,
            ticks: {
                stepSize: 5
            }
          },
          x: {
            ticks: {
              callback: (tick) => (Number(tick) % 60 === 0 ? (labels[tick / 60]) : null) // Replace null with "" to show gridline
            }
          }
        },
        plugins: {
          legend: {
            display: false,  
            //position: 'top',
          },
          title: {
            display: true,
            text: t("feedback.five_min_title"),
            position: 'top'
          },
           
        }
    };
    
    const labels = ['-5min', '-4min', '-3min', '-2min', '-1min', 'Now', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
     '-4min', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
     '-3min', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
     '-2min', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
     '-1min', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
      'Now'];

    const data = {
        labels,
        datasets: [
          {
            label: t("feedback.understanding_perc"),
            data: fiveMinData,
            backgroundColor: 'red',
            borderColor: 'rgb(53, 162, 235)',
            fill: true,
            pointRadius: 0,
          },
        ],
      };
    
    return (
        <div className="h-100">
          <Line options={options} data={data} />
        </div>
    )
}

export default LectureLineChart
