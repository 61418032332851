import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Nav, Row, Col } from 'react-bootstrap';
import LectureDoughnutChart from '../../Feedbacks/LectureDoughnutChart';
import LectureLineChart from '../../Feedbacks/LectureLineChart';
import UnderstandingWarning from '../../Feedbacks/UnderstandingWarning';
import { SocketContext } from '../../../context/SocketContext';
import '../Dashboards.css';

/* Dashboard showing the feedback analysis, in particular it show a doughnut chart and a line chart.
If the understanding % goes under a threshold, then a warning is shown. */
const FeedbackDashboard = () => {
    const { socket } = useContext(SocketContext);
    const [disabled, setDisabled] = useState(false);
    const [understandingData, setUnderstandingData] = useState([0, 0, 0]);
    const [fiveMinData, setFiveMinData] = useState([...Array(301)].map(e => 101));
    const { t } = useTranslation();

    const handleEvent = useCallback(({understandingActive}) => {
        setDisabled(!understandingActive);
    }, []);

    const handleUnderstandingChange = useCallback((understanding) => {
        setUnderstandingData(understanding);
    }, []);

    useEffect(() => {
        socket.on('understandingChange', understanding => {
            handleUnderstandingChange(understanding); 
        });

        socket.on("newFunctionalityStatus", (status) => {
            handleEvent(status);
        });

        return () => {
            socket.off('understandingChange', understanding => {
                handleUnderstandingChange(understanding); 
            });
    
            socket.off("newFunctionalityStatus", (status) => {
                handleEvent(status);
            });
        }
    }, [handleEvent, handleUnderstandingChange, socket]);

    return (
        <Card bg="light" border="light" className="feedback-card">
            <Card.Header className="p-0">
                <Nav className="d-flex flex-md-row justify-content-between tab-title single">
                    <div data-toggle="tab" data-target="#feedbackTab" className="active">{t("dashboard.feedback")}</div>
                </Nav>
            </Card.Header>
            <Card.Body>
                <div className="tab-content h-100">
                    <div className="tab-pane h-100 active" id="feedbackTab">
                        {disabled ?
                        <div className="h-100">
                            <div className="disabled-card"></div>
                            <div className="disabled-card-text">
                                <h5>{t("dashboard.feedback_disabled")}</h5>
                            </div>
                        </div>
                        :
                        <div className="chart-container h-100 w-100">
                            <Row className="h-100 overflow-auto">
                                <Col md={6} className="h-100">
                                    <UnderstandingWarning understandingData={understandingData} />
                                    <LectureDoughnutChart understandingData={understandingData} />
                                </Col>
                                <Col md={6} className="h-100">
                                    <LectureLineChart setFiveMinData={setFiveMinData} fiveMinData={fiveMinData} />
                                </Col>
                            </Row>
                        </div>}
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default FeedbackDashboard
