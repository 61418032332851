import React, { useState, useContext, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import { Button, Card, Nav } from 'react-bootstrap'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { SocketContext } from '../../../context/SocketContext'
import { ProfileContext } from '../../../context/ProfileContext'
import '../Dashboards.css'

/* Control dashboard that shows to the instructor the commands to enable or disable chat, notes and the visibility of the feedback
 to the students. It also shows a button the permits to the instructor to end the lecture. */
const InstructorControlDashboard = () => {
    const [chat, setChat] = useState(true);
    const [notes, setNotes] = useState(true);
    const [understanding, setUnderstanding] = useState(true);
    const { socket } = useContext(SocketContext);    
    const { currentLecture } = useContext(ProfileContext);
    const history = useHistory();
    const { t } = useTranslation();

    const handleChat = (active) => {
        setChat(active);
        sendFunctionalityStatus(active, notes, understanding);
    }

    const handleNotes = (active) => {
        setNotes(active);
        sendFunctionalityStatus(chat, active, understanding);
    }
    
    const handleUnderstanding = (active) => {
        setUnderstanding(active);
        sendFunctionalityStatus(chat, notes, active);
    }

    const sendFunctionalityStatus = (chatStatus, notesStatus, understandingStatus) => {
        socket.emit('functionalityStatus', {
            chatActive: chatStatus,
            notesActive: notesStatus,
            understandingActive: understandingStatus,
            room: currentLecture.lectureUUID
        });
    }

    const onStopLecture = () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: 'Are you sure?',
            text: "You are going to end the lecture.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, end it!'
        }).then((result) => {
            if (result.isConfirmed) {
                socket.emit('endLecture', {
                    room: currentLecture.lectureUUID,
                });        
                MySwal.fire(
                    'Closed!',
                    'The lecture has been ended.',
                    'success'
                );
                history.push('/home');
            }
        })
        
    }

    const handleEvent = useCallback(({chatActive, notesActive, understandingActive}) => {
        setChat(chatActive);
        setNotes(notesActive);
        setUnderstanding(understandingActive);
    }, []);

    useEffect(() => {
        socket.on("newFunctionalityStatus", (status) => {
            handleEvent(status);
        });

        return () => {
            setChat({});
            setNotes({});
            setUnderstanding({});

            socket.off("newFunctionalityStatus", (status) => {
                handleEvent(status);
            });
        }
    }, [socket, handleEvent]);

    return (
        <Card bg="light" border="light">
            <Card.Header className="p-0">
                <Nav className="d-flex flex-md-row justify-content-between tab-title single">
                    <div data-toggle="tab" data-target="#controlTab" className="active">{t("dashboard.control_panel")}</div>
                </Nav>
            </Card.Header>
            <Card.Body>
                <div className="tab-content">
                    <div className="tab-pane active" id="controlTab">
                        <div className="d-flex flex-column justify-content-between">
                            <label className="d-flex flex-row justify-content-between mb-3">
                                <span className="text-uppercase font-weight-bold mr-4">
                                    {t("dashboard.chat")} <i className="far fa-question-circle text-primary" data-bs-toggle="tooltip" title={t("tooltip.chat_toggle")}></i>
                                </span>
                                <Switch onChange={active => handleChat(active)} checked={chat} className="react-switch" />
                            </label>
                            <label className="d-flex flex-row justify-content-between mb-3">
                                <span className="text-uppercase font-weight-bold mr-4">
                                    {t("dashboard.notes")} <i className="far fa-question-circle text-primary" data-bs-toggle="tooltip" title={t("tooltip.notes_toggle")}></i>
                                </span>
                                <Switch onChange={active => handleNotes(active)} checked={notes} className="react-switch" />
                            </label>
                            <label className="d-flex flex-row justify-content-between">
                                <span className="text-uppercase font-weight-bold mr-4">
                                    {t("dashboard.feedback")} <i className="far fa-question-circle text-primary" data-bs-toggle="tooltip" title={t("tooltip.feedback_toggle")}></i>
                                </span>
                                <Switch onChange={active => handleUnderstanding(active)} checked={understanding} className="react-switch" />
                            </label>
                            <Button variant="danger" onClick={onStopLecture}>
                                <i className="fas fa-hourglass-end"></i> {t("dashboard.end_lecture")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default InstructorControlDashboard
