import axios from 'axios';
import { toast } from 'react-toastify';

const http = axios.create();

export const setupInterceptors = (history) => {
    http.interceptors.response.use((response) => {
        //console.log("AXIOS REQUEST");
        return response;
    }, (error) => {
        if(error.response) {
            if (error.response.status === 401) {
                //Clear the local storage
                localStorage.removeItem('user');
                localStorage.removeItem('loginMethod');
                // Unauthorized
                history.push('/error');
            } else if(error.response.status === 400) {
                console.log(error.response)
                const errors = error.response.data;
                errors.forEach(error => {
                    toast.error(error.msg, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            } else {
                return toast.error(error.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    });
}

export default http;