import React, {useContext} from 'react'
import { useTranslation } from 'react-i18next';
import { Col, Card } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "./Courses.css";

/* Card containing the information about a course followed by the user. It contains a button for unenroll from the course.
If the card is clicked, then the list of lecture of the course is shown to the user. */
const FollowedCourseCard = ({ course, setShowCourses }) => {
    
    const { unsubscribeCourse, selectFollowedCourse, getFollowedLectures } = useContext(ProfileContext);
    const { t } = useTranslation();

    const onDelete =  () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: t("swal.title"),
            text: t("swal.delete_text"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("swal.confirm")
          }).then((result) => {
            if (result.isConfirmed) {
                unsubscribeCourse(course.courseUUID);
                MySwal.fire(
                    t("swal.done_unsubscribe"),
                    t("swal.done_unsubscribe_text"),
                    'success'
                )
            }
          })
    }

    const onSelect = () => { 
        selectFollowedCourse(course.courseUUID);
        getFollowedLectures(course.courseUUID);
        setShowCourses(false);
    }
    
    return (
        <Col md={3} sm={4} xs={10} className="content-card">
            <Card className="card-just-text" data-background="color" data-color="blue" data-radius="none">
                <div className="content">
                    <i className="delete grow fas fa-sign-out-alt" onClick={onDelete}
                    data-bs-toggle="tooltip" data-bs-placement="top" title={t("home.courses.unsubscribe_tooltip")}></i>
                    <div className="clickable" onClick={onSelect}>
                        <h6 className="category">{`[${course.teacher.firstName} ${course.teacher.lastName}]`}</h6>
                        <h4 className="title">{course.name}</h4>
                        <p className="description">{t("home.courses.created", { date: new Date(course.createdAt) })}</p>
                    </div>
                </div>
            </Card>
        </Col>
    )
}

export default FollowedCourseCard
