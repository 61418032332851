import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { Card } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';
import "./Notes.css";

/* It shows the detail of the notes, including the notes creator, the text of the note and the possibility to 
insert like or dislike reactions. */
const Note = ({note}) => {
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const { socket } = useContext(SocketContext);

    const voteSum = note.reactions.filter(r => r.value === 1).length - note.reactions.filter(r => r.value === 0).length; 
    const myReaction = note.reactions.filter(r => r.userEmail === currentUser.email)[0];
    const upClass = myReaction && myReaction.value === 1 ? 'fas' : 'far';
    const downClass = myReaction && myReaction.value === 0 ? 'fas' : 'far';

    const onVote = (value) => { 
        socket.emit('reaction', {
            value: value,
            noteUUID: note.noteUUID,
            user: currentUser,
            room: currentLecture.lectureUUID
        });
    }

    return (
        <div className="note-card-big-shadow">
            <Card className="note-card card-just-text" data-backround="color" data-color={note.private ? "blue" : "yellow"} data-radius="none">
                <div className="note-content">
                    <h6 className="category">{note.author.firstName + " " + note.author.lastName}</h6>
                    <div className="description">{parse(note.text)}</div>
                </div>
                <div className="reactions d-flex flex-row align-items-baseline">
                        <i className={`${upClass} fa-thumbs-up`} onClick={() => onVote(1)}></i>
                        <p className="mx-1">{voteSum}</p>
                        <i className={`${downClass} fa-thumbs-down `} onClick={() => onVote(0)}></i>
                    </div>
            </Card>
        </div>
    )
}

export default Note
