import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext';

const LoggedRoute = ({ component: Component, ...rest }) => {
    const { currentUser } = useContext(AuthContext);

    return (
        <Route {...rest} render={(props) => { 
            if(currentUser) return (<Component {...props} />)
            //Redirect to index page
            return (<Redirect to={{path:'/', state: {from: props.location}}}/>)
        }}/>
    )
}

export default LoggedRoute
