import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import "./Presentation.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/* It shows the presentation slides. We use react-pdf library to implement this functionality. */
const PDFViewer = ({presentationPath, setNumPages, pageNumber, scale}) => {
    const [isLoading, setIsLoading] = useState(true);

    const path = 'uploads/docs/' + presentationPath;

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setIsLoading(false);
    }
    
    return (
        <div>
            <section className="d-flex flex-column align-items-center">
                {isLoading && <div id="loader" className="d-flex flex-column justify-content-center align-items-center">
                    <img src="https://react-pdf.org/images/logo.png" alt="loader" className="mb-5" />
                </div>}
                <Document file={path} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} scale={scale} />
                </Document>
            </section>
        </div>
    )
}

export default PDFViewer
