import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../context/ProfileContext';
import { Container, Nav, Card, Row, Col, Button } from 'react-bootstrap'
import AppNavbar from '../components/Navbars/AppNavbar'
import CourseDetailCard from '../components/Courses/CourseDetailCard'
import StudentLectureTable from '../components/Lectures/StudentLectureTable';
import StudentCoursesList from '../components/Courses/StudentCoursesList';
import TeacherCoursesList from '../components/Courses/TeacherCoursesList'
import InstructorLecturesTable from '../components/Lectures/InstructorLecturesTable';
import CreateLectureModal from '../components/Lectures/CreateLectureModal'
import './Pages.css'

const HomePage = () => {
    const [showTaughtCourses, setShowTaughtCourses] = useState(true);
    const [showFollowedCourses, setShowFollowedCourses] = useState(true);
    const [openLectureModal, setOpenLectureModal] = useState(false);

    const { currentFollowedCourse, currentCourse } = useContext(ProfileContext);
    const { t } = useTranslation();

    return (
        <Container fluid className="banner" >
            <Row>
                <AppNavbar /> 
            </Row>
            <Row className="content">
                <Col className="home-section" lg={8} sm={11}>
                    <Card className="home-card" bg="light" border="light">
                        <Card.Header className="p-0">
                            <Nav className="d-flex flex-md-row justify-content-between categories">
                                <div data-toggle="tab" data-target="#studentTab" className="active" onClick={() => setShowFollowedCourses(true)}>{t("home.enrollments")}</div>
                                <div data-toggle="tab" data-target="#instructorTab" onClick={() => setShowTaughtCourses(true)}><span>{t("home.teachings")}</span></div>
                            </Nav>
                        </Card.Header>
                        <Card.Body className="overflow-auto">
                            <div className="tab-content">
                                <div className="tab-pane active" id="studentTab">
                                    <Row>
                                        {showFollowedCourses ?
                                        <Col md={12}>
                                            <StudentCoursesList setShowCourses={setShowFollowedCourses}/>
                                        </Col>
                                        :
                                        <Col md={12}>
                                            <Row className="mb-2">
                                                <Col>
                                                    <CourseDetailCard course={currentFollowedCourse}/>
                                                </Col>
                                                <Col>
                                                    <Button variant="primary" className="float-right" onClick={() => setShowFollowedCourses(true)}>
                                                        <i className="fas fa-undo"></i> {t("home.back_courses")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <StudentLectureTable/>
                                        </Col>}
                                    </Row>
                                </div>
                                <div className="tab-pane" id="instructorTab">
                                    <Row>
                                        {showTaughtCourses ? 
                                        <Col md={12}>
                                            <TeacherCoursesList setShowCourses={setShowTaughtCourses}/>
                                        </Col>
                                        :
                                        <Col md={12}>
                                            <Row className="justify-content-lg-between mb-2">
                                                <Col lg={6} sm={12}>
                                                    <CourseDetailCard course={currentCourse}/>
                                                </Col>
                                                {currentCourse && 
                                                <Col lg={3} className="d-flex flex-column">
                                                    <Button variant="success" className="my-2" onClick={() => setOpenLectureModal(true)}>
                                                        <i className="far fa-calendar-plus"></i> {t("home.create_lecture")}
                                                    </Button>
                                                    <Button variant="primary" onClick={() => setShowTaughtCourses(true)}>
                                                        <i className="fas fa-undo"></i> {t("home.back_courses")}
                                                    </Button>
                                                </Col>}
                                            {openLectureModal && <CreateLectureModal openLectureModal={openLectureModal} setOpenLectureModal={setOpenLectureModal}/>}
                                            </Row>
                                            <InstructorLecturesTable />
                                        </Col>}
                                    </Row>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default HomePage
