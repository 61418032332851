import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../context/ProfileContext';
import { Button, Row, Form, Col } from 'react-bootstrap';
import FollowedCourseCard from './FollowedCourseCard';
import axios from '../../axiosInstance';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './Courses.css';

/* It contains a form to enroll to a new course by inserting its code and the list of courses followed by the student. */
const StudentCoursesList = ({setShowCourses}) => {
    const { followedCourses, getFollowedCourses, subscribeToCourse } = useContext(ProfileContext);
    const { t } = useTranslation();

    const [showForm, setShowForm] = useState(false);
    const [code, setCode] = useState('');

    useEffect(() => {
        getFollowedCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        
        if(code) {
            axios.get('/courses/' + code)
                .then(res => res.data)
                .then(course => {
                    const MySwal = withReactContent(Swal);
                    if(course) {
                        if(followedCourses.map(c => c.courseUUID).includes(course.courseUUID)){
                            MySwal.fire(
                                t("swal.ops"),
                                t("swal.already_enrolled"),
                                'info');
                        }else{
                            MySwal.fire({
                                title: t("swal.title"),
                                text: t("swal.enrolled_to", { name: course.name } ),
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: t("swal.confirm")
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    subscribeToCourse(course);
                                    MySwal.fire(
                                        t("swal.subscribed"),
                                        t("swal.enrolled_confirm"),
                                        'success'
                                    )}
                            });
                        }
                        
                    }else{
                        MySwal.fire(
                            t("swal.no_course_found"),
                            t("swal.no_course_found_sub"),
                            'error');
                    }
                    const form = document.getElementById('enroll-form');
                    form.reset();       
                });
        }
    }

    return (
        <>
            {followedCourses && 
            (<><Row>
                <Col md={12} className="d-flex flex-row align-items-baseline justify-content-between">
                <h5 className="total">{t("home.courses.totalcourses", { count: followedCourses.length })}</h5> 
                <Button variant="success" className="float-right" onClick={() => setShowForm(true)}>
                    <i className="fas fa-plus"></i> {t("home.courses.add_course")}
                </Button>
                
                </Col>
            </Row>
            {showForm &&
            <Row className="justify-content-center my-3">
                <Col lg={4} md={4} sm={9} className="course-form">
                    <h5>{t("home.courses.enroll_to_course")}</h5>
                    <Form id="enroll-form" onSubmit={onSubmit}>
                        <Form.Group className="my-3">
                            <Form.Control required type="text" placeholder={t("home.courses.course_code_placeholder")} onChange={e => setCode(e.target.value)} />
                        </Form.Group>
                        <Button type="submit" variant="primary" className="mr-2">
                            {t("home.courses.submit")}
                        </Button>
                        <Button variant="secondary" onClick={() => setShowForm(false)}>
                            {t("home.courses.cancel")}
                        </Button>
                    </Form>
                </Col>
            </Row>}
            <Row className="mt-3 justify-content-center">
                {followedCourses.map((course) => 
                    <FollowedCourseCard key={course.courseUUID} course={course} setShowCourses={setShowCourses}/>
                )}
            </Row></>)}
        </>
    )
}

export default StudentCoursesList
