import React, { useState } from 'react';
import YouTube from 'react-youtube';
import PacmanLoader from 'react-spinners/PacmanLoader';
import './Video.css';

/* YouTube video player for the Mooc video card */
const MoocVideoPlayer = ({videoId}) => {
    const [loading, setLoading] = useState(true);

    const opts = {
        playerVars: {
            autoplay: 0
        }
    };

    const onReady = () => {
        setLoading(false);
    }

    return (
        <div className="mooc-video-container">
            {loading && <div id="spinner"><PacmanLoader color="#fff" /></div>}
            <YouTube videoId={videoId} onReady={onReady} opts={opts} />
        </div>
    );
};

export default MoocVideoPlayer;
