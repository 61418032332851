import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Nav } from 'react-bootstrap';
import Switch from 'react-switch';
import { SocketContext } from '../../../context/SocketContext';
import { ProfileContext } from '../../../context/ProfileContext';
import '../Dashboards.css';

/* Control dashboard that shows to the instructor the commands to enable or disable chat, notes and the visibility of the feedback
 to the students during the vision of the report. */
const ReportInstructorControlDashboard = () => {
    const [chat, setChat] = useState(true);
    const [notes, setNotes] = useState(true);
    const [understanding, setUnderstanding] = useState(true);
    const { socket } = useContext(SocketContext);    
    const { currentLecture } = useContext(ProfileContext);
    const { t } = useTranslation();

    const handleChat = (active) => {
        setChat(active);
        sendFunctionalityStatus(active, notes, understanding);
    }

    const handleNotes = (active) => {
        setNotes(active);
        sendFunctionalityStatus(chat, active, understanding);
    }
    
    const handleUnderstanding = (active) => {
        setUnderstanding(active);
        sendFunctionalityStatus(chat, notes, active);
    }

    const sendFunctionalityStatus = (chatStatus, notesStatus, understandingStatus) => {
        socket.emit('functionalityStatus', {
            chatActive: chatStatus,
            notesActive: notesStatus,
            understandingActive: understandingStatus,
            room: currentLecture.lectureUUID
        });
    }

    const handleEvent = useCallback(({chatActive, notesActive, understandingActive}) => {
        setChat(chatActive);
        setNotes(notesActive);
        setUnderstanding(understandingActive);
    }, []);

    useEffect(() => {
        socket.on("newFunctionalityStatus", (status) => {
            handleEvent(status);
        });

        return () => {
            socket.off("newFunctionalityStatus", (status) => {
                handleEvent(status);
            });
        }
    }, [socket, handleEvent]);

    return (
        <Card bg="light" border="light">
            <Card.Header className="p-0">
                <Nav className="d-flex flex-md-row justify-content-between tab-title single">
                    <div data-toggle="tab" data-target="#controlTab" className="active">CONTROL PANEL</div>
                </Nav>
            </Card.Header>
            <Card.Body>
                <div className="tab-content">
                    <div className="tab-pane active" id="controlTab">
                        <div className="d-flex flex-column justify-content-between">
                            <label className="d-flex flex-row justify-content-between mb-3">
                                <span className="text-uppercase font-weight-bold mr-4">
                                    {t("dashboard.questions")} <i className="far fa-question-circle text-primary" data-bs-toggle="tooltip" title={t("tooltip.chat_toggle")}></i>
                                </span>
                                <Switch onChange={active => handleChat(active)} checked={chat} className="react-switch" />
                            </label>
                            <label className="d-flex flex-row justify-content-between mb-3">
                                <span className="text-uppercase font-weight-bold mr-4">
                                    {t("dashboard.notes")} <i className="far fa-question-circle text-primary" data-bs-toggle="tooltip" title={t("tooltip.notes_toggle")}></i>
                                </span>
                                <Switch onChange={active => handleNotes(active)} checked={notes} className="react-switch" />
                            </label>
                            <label className="d-flex flex-row justify-content-between">
                                <span className="text-uppercase font-weight-bold mr-4">
                                    {t("dashboard.feedback")} <i className="far fa-question-circle text-primary" data-bs-toggle="tooltip" title={t("tooltip.feedback_toggle")}></i>
                                </span>
                                <Switch onChange={active => handleUnderstanding(active)} checked={understanding} className="react-switch" />
                            </label>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ReportInstructorControlDashboard
