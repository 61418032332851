import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../context/ProfileContext';
import { Button, Form, Row, Col } from 'react-bootstrap'

/* Form able to create a new course inserting its name. */
const CreateCourseForm = ({setShowCreateCourse}) => {
    const MAX_TITLE_LENGTH = 50;

    const [name, setName] = useState('');
    const { t } = useTranslation();
    const { createCourse } = useContext(ProfileContext);
    
    const onSubmit = (e) => {
        e.preventDefault();
        const form = document.getElementById('newCourseForm');
        form.reset();
        const clampedName = name.slice(0, MAX_TITLE_LENGTH);
        createCourse({name: clampedName});
        setShowCreateCourse(false);
    }

    return (
        <Row className="justify-content-center mt-3">
            <Col lg={4} md={4} sm={9} className="course-form">
                <h5>{t("home.courses.create_course")}</h5>
                <Form id='newCourseForm' onSubmit={onSubmit}>
                    <Form.Group className="my-3">
                        <Form.Control required type="text" maxLength={MAX_TITLE_LENGTH} placeholder={t("home.courses.course_name_placeholder")} onChange={(e) => setName(e.target.value)}/>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mr-2">{t("home.courses.submit")}</Button>
                    <Button variant="secondary" onClick={() => setShowCreateCourse(false)}>{t("home.courses.cancel")}</Button>
                </Form>
            </Col>
        </Row>
    )
}

export default CreateCourseForm
