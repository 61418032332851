import React, { createContext, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const initialState = {
    currentUser: null, //The current logged user
}

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AuthReducer, initialState);

    //Login the user into the system setting the current user
    function loginUser(user) {
        dispatch({
            type: 'LOGIN_USER',
            payload: user
        });
    }

    //Logout the user, set the current user to null
    function logoutUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('loginMethod');
        dispatch({
            type: 'LOGOUT_USER',
            payload: null
        });
    }

    return (<AuthContext.Provider value={{
        currentUser: state.currentUser,
        loginUser,
        logoutUser
    }}>
        {children}
    </AuthContext.Provider>)
}