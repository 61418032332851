import React, { useState, useContext, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import { VideoContext } from '../../context/VideoContext';
import { Alert } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';

/* Lecture video player. When the student enter in the lecture the video loads and then a clear understanding
 (value = 2) is sent to the server. When it is unmounted an understanding of -1 is sent. In this way the server can
 understand when a user connects and disconnect from the lecture. */
const LiveLecturePlayer = ({ videoUrl }) => {
    const { setPlayer, setDuration } = useContext(VideoContext);
    const [showError, setShowError] = useState(false);
    const { socket } = useContext(SocketContext);
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const [firstPlayerChange, setFirstPlayerChange] = useState(true);
    const { t } = useTranslation();

    const playerRef = useRef(null);

    const opts = {
        playerVars: {
            autoplay: 1
        }
    };
    
    const onReady = (e) => {
        var player = e.target;
        setShowError(false);
        setPlayer(player);
        //Autoplay
        player.playVideo();        
    }

    /* When the video is loaded and for the first time the player plays the video then
    send an understanding reaction with value 2 = CLEAR */
    const onStateChange = (e) => {
        const player = e.target;
        if(firstPlayerChange && player.getPlayerState() === 1) {
            setFirstPlayerChange(false);
            const duration = player.getDuration();
            setDuration(duration);
            const videoSeconds = parseInt(player.getCurrentTime());
            socket.emit('understanding', {
                room: currentLecture.lectureUUID,
                understanding: 2,
                videoSeconds: videoSeconds,
                user: currentUser
            });
        }
    }

    const onError = (e) => {
        setShowError(true);
    }

    /* UseLayoutEffect runs synchronuosly wrt the component unmount, so we can keep the 
     * ref of the player and get the seconds */
    useLayoutEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const unmountedPlayer = playerRef.current.getInternalPlayer();
            unmountedPlayer.getCurrentTime()
                .then(videoSeconds => {
                    socket.emit('understanding', {
                    room: currentLecture.lectureUUID,
                    understanding: -1,
                    videoSeconds: parseInt(videoSeconds),
                    user: currentUser 
            });
        });
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <YouTube ref={playerRef} videoId={videoUrl} opts={opts} onReady={onReady} onError={onError} onStateChange={onStateChange}/>
            {showError && <Alert variant="danger">{t("video.error")}</Alert>}
        </>
    )
}

export default LiveLecturePlayer