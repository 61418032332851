import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Nav } from 'react-bootstrap';
import UnderstandingSwitch from '../../Feedbacks/UnderstandingSwitch';
import '../Dashboards.css';

/* Student control panel that permits to the user to insert undestanding reactions. */
const ControlDashboard = () => {
    const { t } = useTranslation();

    return (
        <Card bg="light" border="light">
            <Card.Header className="p-0">
                <Nav className="d-flex flex-md-row justify-content-between tab-title single">
                    <div data-toggle="tab" data-target="#controlTab" className="active">{t("dashboard.understanding")}</div>
                </Nav>
            </Card.Header>
            <Card.Body>
                <div className="tab-content">
                    <div className="tab-pane active" id="controlTab">
                        <div className="d-flex flex-column align-items-center justify-content-between">
                            <UnderstandingSwitch/>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ControlDashboard
