import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SocketContext } from '../../context/SocketContext';
import "./Presentation.css";

/* Student control panel. It shows the commands to change page, go to teacher page and change zoom. */
const PDFControlPanel = ({pageNumber, numPages, setPageNumber, scale, setScale}) => {
    const { socket } = useContext(SocketContext);

    const [isFollowing, setIsFollowing] = useState(true);
    const [teacherPage, setTeacherPage] = useState(1);
    const { t } = useTranslation();

    const isFirstPage = pageNumber === 1;
    const isLastPage = pageNumber === numPages;
    const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
    const lastPageClass = isLastPage ? 'disabled' : 'clickable';
    const isMinZoom = scale < 0.6;
    const isMaxZoom = scale >= 2.0;
    const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
    const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';
    const followClass = isFollowing ? 'disabled' : 'clickable';
    
    const goToFirstPage = () => {
        if(!isFirstPage) { 
            setPageNumber(1);
            setIsFollowing(false);
        }
    };

    const goToPreviousPage = () => {
        if(!isFirstPage) { 
            setPageNumber(pageNumber - 1);
            setIsFollowing(false);
        }
    }

    const goToNextPage = () => {
        if(!isLastPage) {
            setPageNumber(pageNumber + 1);
            setIsFollowing(false);        }
    }

    const goToLastPage = () => {
        if(!isLastPage) {
            setPageNumber(numPages);
            setIsFollowing(false);        }
    }

    const onPageChange = (e) => {
        setPageNumber(Number(e.target.value));
        setIsFollowing(false);
    }

    const zoomOut = () => {
        if(!isMinZoom) setScale(scale - 0.1);
    }

    const zoomIn = () => {
        if(!isMaxZoom) setScale(scale + 0.1);
    }

    
    const onFollow = () => {
        setPageNumber(teacherPage);
        setIsFollowing(true);
    }

    const handleTeacherChangePage = useCallback((pageNumber) => {
        setTeacherPage(pageNumber);
    }, [setTeacherPage]);

    useEffect(() => {
        if(isFollowing) setPageNumber(teacherPage);

        socket.on('instructorChangePage', ({pageNumber}) => 
            handleTeacherChangePage(pageNumber));

        return () => {
            socket.off('instructorChangePage', ({pageNumber}) => 
                handleTeacherChangePage(pageNumber));
        }
    }, [handleTeacherChangePage, isFollowing, setPageNumber, socket, teacherPage]);

    return (
        <div className="control-panel d-flex align-items-baseline justify-content-between">
            <div className="d-flex justify-content-between align-items-baseline">
                <i className={`fas fa-fast-backward mx-2 ${firstPageClass}`} onClick={goToFirstPage}></i>
                <i className={`fas fa-backward mx-2 ${firstPageClass}`} onClick={goToPreviousPage}></i>
                <span>
                    {t("presentation.page")} <input name="pageNumber" onChange={onPageChange} type="number" value={pageNumber} min={1} max={numPages || 1} className="p-0 pl-1 mx-2" />
                    {t("presentation.of")} {numPages}
                </span>
                <i className={`fas fa-forward mx-2 ${lastPageClass}`} onClick={goToNextPage}></i>
                <i className={`fas fa-fast-forward mx-2 ${lastPageClass}`} onClick={goToLastPage}></i>
                <i className={`fas fa-circle mx-2 ${followClass}`} onClick={onFollow}> {t("presentation.follow_teacher")}</i>
            </div>
            <div className="d-flex justify-content-between align-items-baseline">
                <i className={`fas fa-search-minus mx-2 ${zoomOutClass}`} onClick={zoomOut}></i>
                <span>{(scale * 100).toFixed()}%</span>
                <i className={`fas fa-search-plus mx-2 ${zoomInClass}`} onClick={zoomIn}></i>
            </div>
        </div>
    )
}

export default PDFControlPanel
