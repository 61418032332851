import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Card } from 'react-bootstrap';
import MoocVideoPlayer from '../Video/MoocVideoPlayer';
import moment from 'moment';
import './Lectures.css';

const MoocLectureCard = ({ lecture }) => {
    const { t } = useTranslation();

    const getStatus = () => {
        const now = moment();
        if(now.isAfter(lecture.scheduledStartTime) && now.isBefore(lecture.scheduledEndTime)) {
            return { status: t("lectures.live"), variant: "success"};
        }
        if(now.isAfter(lecture.scheduledEndTime)) {
            return { status: t("lectures.ended"), variant: "warning" };
        }
        return { status: t("lectures.scheduled"), variant: "info" };
    }

    return (
        <Card className="mooc-card">
            <Card.Header>
                <Card.Title>{lecture.course.name}</Card.Title>
                <Card.Subtitle>{lecture.name}</Card.Subtitle>
                <Badge bg={getStatus().variant}>{getStatus().status}</Badge>
                <p><i className="far fa-clock"></i> {`${moment(lecture.scheduledStartTime).format("YYYY MMM DD HH:mm")} : ${moment(lecture.scheduledEndTime).format("HH:mm")} `}</p>
                <p><i className="fas fa-user-tie"></i> {`${lecture.course.teacher.firstName} ${lecture.course.teacher.lastName}`}</p>
            </Card.Header>
            <Card.Body>
                <MoocVideoPlayer videoId={lecture.youtubeVideoURL} />
            </Card.Body>
        </Card>
    );
};

export default MoocLectureCard;
