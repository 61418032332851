import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';
import { format as formatDate, isDate } from "date-fns";
import { enGB, it } from "date-fns/locale"; // import all locales we need

const locales = { enGB, it }; // used to look up the required locale

i18n
  .use(HttpApi) 
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: ['en', 'it'],
    fallbackLng: "en",
    debug: false,
    detection: {
        // order and from where user language should be detected
        order: ['path', 'cookie', 'htmlTag'],
        caches: ['cookie']
    },
    backend: {
        loadPath: '/assets/locales/{{lng}}/translation.json'
    },
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value)) {
            const locale = locales[lng];
            if (format === "short")
                return formatDate(value, "P", { locale });
            if (format === "long")
                return formatDate(value, "PPPP", { locale });
            if (format === "time")
              return formatDate(value, "pp", { locale });
            if (format === "datetime")
              return formatDate(value, "Pp", { locale });
            return formatDate(value, format, { locale });
        }
    
        return value;
      }
    }
  });

export default i18n;