import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SocketContext } from '../context/SocketContext';
import { ProfileContext } from '../context/ProfileContext';
import { AuthContext } from '../context/AuthContext';
import { Container, Row, Col } from 'react-bootstrap';
import CommunicationDashboard from '../components/Dashboards/LiveLectureDashboards/CommunicationDashboard';
import FeedbacksDashboard from '../components/Dashboards/LiveLectureDashboards/FeedbacksDashboard';
import PresentationDashboard from '../components/Dashboards/LiveLectureDashboards/PresentationDashboard';
import ControlDashboard from '../components/Dashboards/LiveLectureDashboards/ControlDashboard';
import AppNavbar from '../components/Navbars/AppNavbar';
import ParticipantsDashboard from '../components/Dashboards/LiveLectureDashboards/ParticipantsDashboard';
import LiveLectureVideoCard from '../components/Video/LiveLectureVideoCard';
import { toast } from 'react-toastify';
import './Pages.css';

const StudentLecturePage = () => {
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const { socket, connectSocket } = useContext(SocketContext);
    const [pageNumber, setPageNumber] = useState(1);
    const history = useHistory();
    const { t } = useTranslation();
    const attempts = useRef(0); //Keep track of the number of reconnection attempts

    const handleEndLecture = useCallback(() => {
        history.push('/home');
    }, [history]);

    useEffect(() => {  
        if(!socket.connected) {
            connectSocket(socket);

            //Successfully connected
            socket.on('connect', () => {
                if(attempts.current > 0) {
                    toast.success(t("toast.socket_reconnected"), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                //Tell to the server a new student has joined the lecture
                socket.emit('joinLecture', {
                    user: currentUser, 
                    room: currentLecture.lectureUUID
                });
                
                socket.on("instructorEndLecture", () => 
                    handleEndLecture()
                );
                attempts.current = 0;
            });

            //Connection error, redirect to the home page with an error message
            socket.on("connect_error", (err) => {
                //For three time try to reconnect
                if(attempts.current < 5) {
                    toast.error(t("toast.socket_error", { attempt: attempts.current + 1 }), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    attempts.current++;
                }else{ //Redirect to home page
                    toast.error(t("toast.socket_fail", { attempt: attempts.current + 1 }), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    history.push('/home'); //redirect
                }
            });  
        }
        
        //When the user exit from the page he leaves the lecture
        return () => {
            socket.off("connect");
            socket.off("connect_error");

            if(socket.connected) {
                socket.off("instructorEndLecture");
                
                socket.emit('leaveLecture', {
                    user: currentUser,
                    room: currentLecture.lectureUUID,
                });

                socket.disconnect();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLecture.lectureUUID, currentUser, socket, handleEndLecture]);
    
    return (
        <Container fluid className="banner">
            <Row>
                <AppNavbar />
            </Row>
            <Row>
                <Col lg={5} sm={9}>
                    <LiveLectureVideoCard />
                </Col>
                <Col lg={2} sm={3}>
                    <ControlDashboard />
                </Col>
                <Col lg={5} sm={12}>
                    {currentLecture.presentationPath ?
                   <PresentationDashboard pageNumber={pageNumber} setPageNumber={setPageNumber} /> 
                    :
                    <ParticipantsDashboard />
                    }        
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <FeedbacksDashboard />
                </Col>
                <Col lg={6} sm={12}>
                    <CommunicationDashboard pageNumber={pageNumber} />
                </Col>
            </Row>
        </Container>
    )
}

export default StudentLecturePage
