import React, { createContext, useReducer } from "react";
import AppReducer from './ProfileReducer';
import axios from '../axiosInstance';

//Initial state
const initialState = {
    courses: [], //The list of taught courses by the current user
    currentCourse: null, //The selected taught course
    followedCourses: [], //The list of followed courses by the current user
    currentFollowedCourse: null, //The selected followed course
    lectures: [], //List of lectures of the selected taught course
    currentLecture: null, //The selected lecture
    followedLectures: [], //The list of lecture of the selected followed course
}

//Create context
export const ProfileContext = createContext(initialState);

//Provider component
export const ProfileProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    //Actions
    //Retrieve and set the taught courses by the current user
    async function getCourses() {
        try{
            const res = await axios.get('courses/instructor');

            dispatch({
                type: 'GET_COURSES',
                payload: res.data
            });
        }catch(err) {
            console.log(err);
        }
    }

    //Retrieve and set the followed course by the current user
    async function getFollowedCourses() {
        try{
            const res = await axios.get('courses/followed');

            dispatch({
                type: 'GET_FOLLOWED_COURSES',
                payload: res.data
            });
        }catch(err) {
            console.log(err);
        }
    }

    //Retrieve and set the current taught course
    async function selectCourse(courseUUID) {
        try{
            const res = await axios.get('/courses/' + courseUUID);

            dispatch({
                type: 'SELECT_COURSE',
                payload: res.data
            });
        }catch(err) {
            console.log(err);
        }
    }

    //Retrieve and set the current followed course
    async function selectFollowedCourse(courseUUID) {
        try{
            const res = await axios.get('/courses/' + courseUUID);

            dispatch({
                type: 'SELECT_FOLLOWED_COURSE',
                payload: res.data
            });
        }catch(err) {
            console.log(err);
        }
    }

    //Retrieve and set the lecture of the selected taught course
    async function getLectures(courseUUID) {
        try{ 
            const res = await axios.get('lectures/courseLectures/' + courseUUID);

            dispatch({
                type: 'GET_LECTURES',
                payload: res.data
            });
        }catch(err) {
            console.log(err);
        }
    }

    //Retrieve and set the lecture of the selected followed course
    async function getFollowedLectures(courseUUID) {
        try{ 
            const res = await axios.get('lectures/courseLectures/' + courseUUID);

            dispatch({
                type: 'GET_FOLLOWED_LECTURES',
                payload: res.data
            });
        }catch(err) {
            console.log(err);
        }
    }

    //Create a new course and add it to the list of taught courses
    async function createCourse(course) {
        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      
          try {
            const res = await axios.post('/courses/newCourse', course, config);
      
            dispatch({
              type: 'CREATE_COURSE',
              payload: res.data
            });
          } catch (err) {
            console.log(err);
          }
    }

    //Enroll a student to a course and add it to the list of followed course
    async function subscribeToCourse(course) {
        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
        }
      
        try {
            await axios.post('/courses/enroll', {
                'courseUUID': course.courseUUID, config
            });
    
        
            dispatch({
                type: 'SUBSCRIBE_COURSE',
                payload: course
            });
        
        } catch (err) {
            console.log(err);
        }
    }

    async function deleteCourse(courseUUID) {
        try{
            const res = await axios.delete('/courses/' + courseUUID);

            if(res.data.errors) {
                dispatch({
                    type: 'ERROR',
                    payload: res.data.errors
                });    
            }else{
                dispatch({
                    type: 'DELETE_COURSE',
                    payload: courseUUID
                });
            }
        }catch (err) {
            console.log(err);
        }
    }

    //Unenroll a student from a course and remove it from the list of followed courses
    async function unsubscribeCourse(courseUUID) {
        try{
            await axios.delete('/courses/enroll/' + courseUUID);
            dispatch({
                type: 'UNSUBSCRIBE_COURSE',
                payload: courseUUID
            });
        }catch (err) {
            console.log(err);
        }
    }

    //Delete a lecture of a taught course
    async function deleteLecture(lectureUUID) {
        try{
            await axios.delete('/lectures/deleteLecture/' + lectureUUID);
            dispatch({
                type: 'DELETE_LECTURE',
                payload: lectureUUID
            });
        }catch (err) {
            console.log(err);
        }
    }
    
    //Create a lecture and add it to the list of the lectures of the current selected course
    async function createLecture(lecture) {
        const data = new FormData();
        data.append('name', lecture.name);
        data.append('scheduledStartTime', lecture.scheduledStartTime);
        data.append('scheduledEndTime', lecture.scheduledEndTime);
        data.append('courseUUID', lecture.courseUUID);
        data.append('youtubeVideoURL', lecture.youtubeVideoURL);
        data.append('file', lecture.fileData);
        data.append('mooc', lecture.mooc);

        try {
            const res = await axios.post('/lectures/newLecture', data, {
                headers: { 
                    'Content-Type': 'multipart/form-data'     
                }});
            dispatch({
              type: 'CREATE_LECTURE',
              payload: res.data
            });
        } catch (err) {
            console.log(err);
        }
    }

    //Update lecture information
    async function updateLecture(lecture) {
        const data = new FormData();
        data.append('lectureUUID', lecture.lectureUUID);
        data.append('name', lecture.name);
        data.append('scheduledStartTime', lecture.scheduledStartTime);
        data.append('scheduledEndTime', lecture.scheduledEndTime);
        data.append('courseUUID', lecture.courseUUID);
        data.append('mooc', lecture.mooc);
        if(lecture.youtubeVideoURL) data.append('youtubeVideoURL', lecture.youtubeVideoURL);
        if(lecture.fileData) data.append('file', lecture.fileData);

        try {
            const res = await axios.post('/lectures/updateLecture', data, {
                headers: { 
                    'Content-Type': 'multipart/form-data'     
                }});
      
            dispatch({
              type: 'UPDATE_LECTURE',
              payload: res.data
            });
        } catch (err) {
            console.log(err);
        }
    }

    //Update a lecture removing its presentation
    async function removePresentation(lecture) {
        try {
            axios.post('/lectures/removePresentation', {
                lectureUUID: lecture.lectureUUID
            }).then(res => {
                dispatch({
                    type: 'UPDATE_LECTURE',
                    payload: res.data
                });
            })
        }catch(err) {
            console.log(err);
        }
    }

    //Select the current lecture
    function selectLecture(lecture) {
        dispatch({
            type: 'SELECT_LECTURE',
            payload: lecture
        })
    }

    return (<ProfileContext.Provider value={{
        courses: state.courses,
        followedCourses: state.followedCourses,
        currentCourse: state.currentCourse,
        currentFollowedCourse: state.currentFollowedCourse,
        lectures: state.lectures,
        followedLectures: state.followedLectures,
        currentLecture: state.currentLecture,
        error: state.error,
        loading: state.loading,
        getCourses,
        getFollowedCourses,
        createCourse,
        deleteCourse,
        selectCourse,
        selectFollowedCourse,
        getLectures,
        getFollowedLectures,
        createLecture,
        updateLecture,
        selectLecture,
        deleteLecture,
        removePresentation,
        subscribeToCourse,
        unsubscribeCourse
        }}>
        {children}
    </ProfileContext.Provider>);
}