import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import EnrolledStudentsModal from './EnrolledStudentsModal';
import "./Courses.css";

/* Card containing the information about a course taught by the user. It contains three buttons: 
one to copy the course code, one to delete the course and one to show the enrolled students. When the card is clicked the list of lecture
 of such course is shown to the user. */
const TaughtCourseCard = ({course, setShowCourses}) => {
    const { selectCourse, getLectures, deleteCourse } = useContext(ProfileContext);    
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const target = useRef(null);
    const { t } = useTranslation();

    const onDelete = () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: t("swal.title"),
            text: t("swal.delete_text"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                deleteCourse(course.courseUUID);
                MySwal.fire(
                    t("swal.deleted"),
                    t("swal.deleted_sub"),
                    'success'
                )
            }
          })
    }

    const onSelect = () => {
        selectCourse(course.courseUUID);
        getLectures(course.courseUUID);
        setShowCourses(false);
    }

    const onCopy = () => {
        toast.info(t("toast.code_copied"), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <Col md={3} sm={4} xs={10} className="content-card">
            <Card className="card-just-text" data-background="color" data-color="blue" data-radius="none">
                <div className="content">
                    <i className="delete grow fas fa-trash" onClick={onDelete} data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.delete_course")}></i>
                    <i className="students grow fas fa-users" onClick={() => setShowModal(true)} data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.students")}></i>
                    <CopyToClipboard text={course.courseUUID} onCopy={onCopy}>
                        <i className="copy grow far fa-copy" ref={target} onClick={() => setShow(!show)}></i>
                    </CopyToClipboard>
                    <div className="clickable w-100 mt-2" onClick={onSelect}>
                        <h4 className="title">{course.name}</h4>
                        <p className="description">{t("home.courses.created", { date: new Date(course.createdAt) })}</p>        
                    </div>
                </div>
            </Card>
            <EnrolledStudentsModal showModal={showModal} setShowModal={setShowModal} students={course.enrolledStudents}/>
        </Col>
    )
}

export default TaughtCourseCard
