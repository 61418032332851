import React, { createContext, useReducer } from 'react';
import VideoReducer from './VideoReducer';

const initialState = {
    player: null, //Video player reference
    duration: 0 //Video duration
}

export const VideoContext = createContext(initialState);

export const VideoProvider = ({children}) => {
    const [state, dispatch] = useReducer(VideoReducer, initialState);

    //Actions
    //Set the video player
    function setPlayer(player) {
        dispatch({
            type: 'SET_PLAYER',
            payload: player
        })
    }

    //Set the video duration once the video player is ready
    function setDuration(duration) {
        dispatch({
            type: 'SET_DURATION',
            payload: duration
        })
    }



    return (<VideoContext.Provider value={{
        player: state.player,
        duration: state.duration,
        setPlayer,
        setDuration,
    }}>
        {children}
    </VideoContext.Provider>);
}