import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Nav } from 'react-bootstrap';
import ReportLineChart from '../../Feedbacks/ReportLineChart';
import '../Dashboards.css';

/* Like the student dashboard it show the line chart with the understanding % trend in the lecture
but this dashboard is always visible and cannot be disabled. */
const ReportInstructorFeedbackDashboard = () => {
    const { t } = useTranslation();

    return (
        <Card bg="light" border="light" className="feedback-card">
            <Card.Header className="p-0">
                <Nav className="d-flex flex-md-row justify-content-between tab-title single">
                    <div data-toggle="tab" data-target="#feedbackTab" className="active">{t("dashboard.feedback")}</div>
                </Nav>
            </Card.Header>
            <Card.Body>
                <div className="tab-content h-100">
                    <div className="chart-container h-100 w-100">
                        <ReportLineChart />
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ReportInstructorFeedbackDashboard