import React, { createContext, useReducer } from "react";
import SocketReducer from "./SocketReducer";
import io from 'socket.io-client';

const initialState = {
    //Client socket reference
    socket: io("/", {
        autoConnect: false,
        reconnectionAttempts: 6
    })
}

export const SocketContext = createContext(initialState);

export const SocketProvider = ({ children }) => {
    const [state, dispatch] = useReducer(SocketReducer, initialState);

    //Connect the socket to a socket server
    function connectSocket() {
        dispatch({
            type: "CONNECT",
            payload: null
        });
    }

    return (<SocketContext.Provider value={{
        socket: state.socket,
        connectSocket,
    }}>
        {children}
    </SocketContext.Provider>)
}