import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Container, Modal, Button, Form} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import axios from '../../axiosInstance';

/* It permits to the user to edit its profile information: first and last name and 
eventually the password. */
const ProfileModal = ({showModal, setShowModal}) => {
    const { currentUser, loginUser } = useContext(AuthContext);
    const { t } = useTranslation();

    const [firstName, setFirstName] = useState(currentUser.firstName);
    const [lastName, setLastName] = useState(currentUser.lastName);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [changePassword, setChangePassword] = useState(false); 
    const [errors, setErrors] = useState([]);
    const [editSuccess, setEditSuccess] = useState(false);

    const loginMethod = localStorage.getItem('loginMethod');

    const onSubmit = (e) => {
        e.preventDefault();

        if(loginMethod === 'normal') {
            axios.post('/auth/editProfile', {
                firstName: firstName,
                lastName: lastName,
                oldPassword: oldPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            }).then(res => res.data)
            .then(data => {
                if(data.profileStatus === 'ko'){
                    setErrors(data.errors);
                    setEditSuccess(false);
                }else if(data.profileStatus === 'ok') {
                    loginUser(data.user);
                    setEditSuccess(true);
                    setErrors([]);
                }
            });
        }
    }

    return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
            <Modal.Title>{t("navbar.profile.title")}</Modal.Title>
        </Modal.Header>

        <Container>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    {errors.length > 0 && <Alert variant="warning">
                        {errors.map((error, index) => ( 
                            <p key={index}>{error.msg}</p>
                        ))}
                    </Alert>}
                    {editSuccess && <Alert variant="success">
                        {t("navbar.profile.edit_success")}
                    </Alert>}
                    <Form.Group className="mb-3">
                        <Form.Label>{t("navbar.profile.email")}</Form.Label>
                        <Form.Control disabled type="text" value={currentUser.email} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("navbar.profile.first_name")}</Form.Label>
                        {loginMethod === 'google' ? 
                        <Form.Control disabled type="text" defaultValue={currentUser.firstName} onChange={(e) => setFirstName(e.target.value)}/>
                        :
                        <Form.Control required type="text" defaultValue={currentUser.firstName} onChange={(e) => setFirstName(e.target.value)}/>}
                        </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("navbar.profile.last_name")}</Form.Label>
                        {loginMethod === 'google' ? 
                        <Form.Control disabled type="text" defaultValue={currentUser.lastName} onChange={(e) => setLastName(e.target.value)}/>
                        :
                        <Form.Control required type="text" defaultValue={currentUser.lastName} onChange={(e) => setLastName(e.target.value)}/>}
                    </Form.Group>
                    {loginMethod === 'normal' &&
                    <><div className="d-flex justify-content-center">
                        <Button variant="info" className="mb-2" onClick={() => setChangePassword(value => !value)}>
                            <i className="fas fa-lock"></i> {changePassword ? t("navbar.profile.no_password") : t("navbar.profile.yes_password")}
                        </Button>
                    </div>
                    {changePassword &&
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("navbar.profile.old_password")}</Form.Label>
                            <Form.Control type="password" onChange={(e) => setOldPassword(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("navbar.profile.new_password")}</Form.Label>
                            <Form.Control type="password" onChange={(e) => setNewPassword(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("navbar.profile.confirm_password")}</Form.Label>
                            <Form.Control type="password" onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </Form.Group>
                    </>}</>}
                </Modal.Body>

                <Modal.Footer>
                    {loginMethod === 'normal' && 
                    <><Button variant="primary" type="submit">
                        {t("navbar.profile.save")}
                    </Button>
                    <Button variant="info" type="reset">
                        {t("navbar.profile.reset")}
                    </Button></>}
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        {t("navbar.profile.close")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Container>
    </Modal>
  );
};

export default ProfileModal;
