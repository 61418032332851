import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from '../../axiosInstance';
import { ProfileContext } from '../../context/ProfileContext';
import { VideoContext } from '../../context/VideoContext';
import zoomPlugin from 'chartjs-plugin-zoom';

/* Line chart that show the undestanding % trend from the beginning to lecture until the current moment. */
const RecapLineChart = () => {
    const [understandings, setUnderstandings] = useState([]);
    const [currentTime, setCurrentTime] = useState(0);
    const { currentLecture } = useContext(ProfileContext);
    const { player } = useContext(VideoContext);
    const { t } = useTranslation();

    useEffect(() => {
      axios.get('/lectures/understandings/' + currentLecture.lectureUUID)
        .then(res => {
          //Map the understandings on points made as: {x, y}
          const unds = res.data.map(und => {
            const y = (und.globalUp + und.globalMid + und.globalDown !== 0) ? (und.globalUp + 0.5 * und.globalMid) / (und.globalUp + und.globalMid + und.globalDown) * 100 : -10;
            return ({
            x: und.videoSeconds, 
            y: y
          })});
          //Get graph current time
          const time = parseInt(player.getCurrentTime());
          setCurrentTime(time);
          //Add one last point to add a line from the last point to the graph length
          if(unds.length > 0) {
            unds.push({
              x: time,
              y: unds[unds.length - 1].y
            });
          }
          setUnderstandings(unds);
      });

      return () => {
        setUnderstandings([]);
      }
    }, [currentLecture.lectureUUID, player]);
    
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        zoomPlugin
      );

    const options = {
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
            max: 100,
            
          },
          x: {
            type: 'linear',
            beginAtZero: true,
            min: 0,
            max: currentTime,
            ticks: {
              beginAtZero: true,
              callback: (tick) => Math.floor(tick / 60) + ":" + (tick % 60 < 10 ? "0" + Math.floor(tick % 60) : Math.floor(tick % 60))
            }
          }
        },
        plugins: {
          legend: {
            display: false,  
          },
          title: {
            display: true,
            text: t("feedback.recap_title"),
            position: 'top'
          },
          zoom: {
            limits: { 
              x: { min: 0, max: currentTime, minRange: 5 }
            },
            zoom: {
              wheel: {
                enabled: true, // SET SCROOL ZOOM TO TRUE
              },
              mode: "x",
              speed: 0.1,
            },
            pan: {
              enabled: true,
              mode: "x",
            },
          }
        },
        interaction: {
          mode: 'point'
        },
        onClick: (e, element) => {
          if(element.length > 0) {
            var ind = element[0].index;
            var point = data.datasets[0].data[ind]; //point.x = videoSeconds  
            player.seekTo(point.x); 
          }
        }
    };

    const data = {
        datasets: [
          {
            label: t("feedback.clearity_perc"),
            data: understandings,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            fill: false,
            stepped: true
          }
        ],
      };
    
    return (
        <div className="h-100">
          <Line options={options} data={data} />
        </div>
    )
}

export default RecapLineChart