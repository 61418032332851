import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Badge } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ProfileContext } from '../../context/ProfileContext';
import axios from '../../axiosInstance';
import FileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import './Lectures.css';
/* Table row that shows the information about a lecture, such as the name, the timetable, the status and the possible actions.
The actions are: go to live lecture, download slides, download notes, go to report. */
const StudentLectureTableRow = ({lecture}) => {
    const { selectLecture } = useContext(ProfileContext);
    const { t } = useTranslation(); 

    const onDownload = () => {
        toast.info(t("lectures.download"), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
        axios({
            url: "lectures/downloadPDF/" + lecture.lectureUUID,
            method: "GET",
            responseType: "blob",
        }).then(res => {
            if(res.data?.err) {
                toast.error(t("lectures.impossible_download"), {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else FileDownload(res.data, lecture.name + "-"+moment().format("YYYY-MM-DDHH:mm:ss") + ".pdf");  
        })
    }

    /* const onDownloadNotes = () => {
        toast.info(t("lectures.download"), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        axios({
            url: "lectures/downloadNotes/" + lecture.lectureUUID,
            method: "GET",
            responseType: "blob",
        }).then(res => {
            if(res.data) {
                FileDownload(res.data, `${lecture.name}-notes.pdf`);
            }  
        });
    } */

    const getStatus = () => {
        const now = moment();
        if(now.isAfter(lecture.scheduledStartTime) && now.isBefore(lecture.scheduledEndTime)) {
            return { code: 1, status: t("lectures.live"), variant: "success"};
        }
        if(now.isAfter(lecture.scheduledEndTime)) {
            return { code: 2, status: t("lectures.ended"), variant: "warning" };
        }
        return { code: 3, status: t("lectures.scheduled"), variant: "info" };
    }

    return (
        <tr className="text-center">
            <td className="text-center">
                <strong>{lecture.name}</strong>
            </td>
            <td className="text-center">
                {moment(lecture.scheduledStartTime).format('MMM Do YYYY')}
            </td>
            <td className="text-center">
                {moment(lecture.scheduledStartTime).format('h:mm a') 
                + " - " + 
                moment(lecture.scheduledEndTime).format('h:mm a')}
            </td>
            <td className="text-center">
                <Badge bg={getStatus().variant}>{getStatus().status}</Badge>
            </td>
            <td className="text-center">
                {getStatus().code === 1 &&
                <Link to="/studentLecture">
                    <Button variant="success" className="mr-1" onClick={() => selectLecture(lecture)}
                    data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.show_lecture")}>
                        <i className="fas fa-eye"></i>
                    </Button></Link>}
                {lecture.presentationPath &&
                <Button variant="primary" className="mr-1" onClick={onDownload}
                data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.download_slides")}>
                    <i className="fas fa-file-download"></i>
                </Button>}
                {getStatus().code === 2 &&
                <>
                    {/* <Button variant="info" className="mr-1" onClick={onDownloadNotes}
                    data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.download_notes")}>
                        <i className="fas fa-book"></i>
                    </Button> */}
                    <Link to="/report">
                        <Button variant="warning" className="mr-1" onClick={() => selectLecture(lecture)}
                        data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.show_report")}>
                            <i className="far fa-file-alt"></i>
                        </Button>
                    </Link>
                </>}
            </td>
        </tr>
    )
}

export default StudentLectureTableRow
