import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import { VideoContext } from '../../context/VideoContext';
import { Alert } from 'react-bootstrap';

/* YouTube video player. It exploits the YouTube Iframe player API to shows a YouTube video and control it.
In case the video has an incorrect link, then it shows an error alert. */
const YouTubePlayer = ({ videoUrl }) => {
    const { setPlayer, setDuration } = useContext(VideoContext);
    const [showError, setShowError] = useState(false);
    const { t } = useTranslation();

    const opts = {
        playerVars: {
            autoplay: 1
        }
    };
    
    const onReady = (e) => {
        var player = e.target;
        setShowError(false);
        setPlayer(player);
        setDuration(player.getDuration());
    }

    const onError = (e) => {
        setShowError(true);
    }

    return (
        <>
            <YouTube videoId={videoUrl} opts={opts} onReady={onReady} onError={onError}/>
            {showError && <Alert variant="danger">{t("video.error")}</Alert>}
        </>
    )
}

export default YouTubePlayer
