import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, Card, Badge } from 'react-bootstrap';
import ParticipantsBox from '../../Participants/ParticipantsBox';
import '../Dashboards.css';

/* Dashboard that shows the list of the lecture participants. It also show the participants
number in a badge. It is shown only when there is no presentation. */
const ParticipantsDashboard = () => {
    const [participantsNumber, setParticipantsNumber] = useState(0);
    const { t } = useTranslation();

    return (
        <Card bg="light" border="light">
            <Card.Header className="p-0">
                <Nav className="d-flex flex-md-row justify-content-between tab-title single">
                    <div data-toggle="tab" data-target="#singleParticipantsTab" className="active"> 
                        {t("dashboard.participants")} <Badge pill bg="light">{participantsNumber}</Badge> 
                    </div>
                </Nav>       
            </Card.Header>
            <Card.Body className="card-container big-card-content-scrollable">
                <div className="tab-content h-100">
                    <div className="tab-pane h-100 active" id="singleParticipantsTab">
                        <ParticipantsBox setParticipantsNumber={setParticipantsNumber} />
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ParticipantsDashboard
