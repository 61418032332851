import React, { useState, useEffect, useCallback} from "react";
import { useTranslation, Trans } from 'react-i18next';
import { Container, Form, Row, Col, Button, Pagination } from "react-bootstrap";
import MoocLectureCard from "../components/Lectures/MoocLectureCard";
import AppNavbar from "../components/Navbars/AppNavbar";
import axios from '../axiosInstance';
import "./Pages.css";

const MoocPage = () => {
    const [lectures, setLectures] = useState([]);
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const { t } = useTranslation();

    const elementPerPage = 12;
    const lastPage = 1 + Math.floor(count / elementPerPage); 
    const prevPage = page === 1 ? 1 : page - 1;
    const nextPage = page === lastPage ? lastPage : page + 1;

    const search = useCallback(() => {
        axios.get(`/lectures/moocs/${page}/${term}`)
            .then(response => response.data)
            .then(lecturesData => {
                setLectures(lecturesData.lectures);
                setCount(lecturesData.count);
            })
            .catch(err => console.log(err));
    }, [page, term]);

    useEffect(() => {
        search();
    }, [search]);
 

    const onSubmit = (e) => {
        e.preventDefault();

        search(term);
    }

    return (
        <Container fluid className="banner">
            <Row>
                <AppNavbar />
            </Row>
            <Row className="justify-content-lg-center mb-3">
                <Col lg={8} sm={12} className="mooc-title-section">
                    <h1 className="mooc-title">{t("mooc.title")}</h1>
                    <h5>
                        <Trans components={{ bold: <span className="font-weight-bold" />}}>
                            mooc.subtitle
                        </Trans>
                    </h5>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <div className="mooc-search">
                    <h5 className="mr-2">{t("mooc.search")}</h5>
                    <Form onSubmit={onSubmit}>
                        <Form.Control size="sm" type="text" className="mr-2" onChange={(e) => setTerm(e.target.value)}></Form.Control>
                        <Button type="submit" variant="light">
                            <i className="fas fa-search"></i>
                        </Button>
                    </Form>
                </div>
            </Row>
            <Row className="justify-content-center">
                <Col lg={11}>
                    <Row>
                        {lectures.map(lecture => 
                        <Col key={lecture.lectureUUID} lg={3} md={6} sm={6} xs={12}>
                            <MoocLectureCard lecture={lecture} />
                        </Col>)}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Pagination size="lg" className="justify-content-center">
                        <Pagination.First onClick={() => setPage(1)}/>
                        <Pagination.Prev onClick={() => setPage(prevPage)} />
                        <Pagination.Item>{page}</Pagination.Item>
                        <Pagination.Next onClick={() => setPage(nextPage)}/>
                        <Pagination.Last onClick={() => setPage(lastPage)}/>
                    </Pagination>
                </Col>
            </Row>
        </Container>
  );
};

export default MoocPage;
