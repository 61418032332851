import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../context/ProfileContext';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';
import './Chat.css';

/* It shows an answer to a questiona message. It is possible to insert like or dislike reaction with the apposite buttons. */
const Answer = ({ answer }) => {
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const { socket } = useContext(SocketContext);
    const { t } = useTranslation();

    const voteSum = answer.reactions.filter(r => r.value === 1).length - answer.reactions.filter(r => r.value === 0).length; 
    const myReaction = answer.reactions.filter(r => r.userEmail === currentUser.email)[0];
    const upClass = myReaction && myReaction.value === 1 ? 'fas' : 'far';
    const downClass = myReaction && myReaction.value === 0 ? 'fas' : 'far';
    
    const onVote = (value) => { 
        socket.emit('reaction', {
            value: value,
            answerUUID: answer.answerUUID,
            user: currentUser,
            room: currentLecture.lectureUUID
        });
    }

    return (
        <div className="box-comment">
            <div className="comment-text">
                <div className="d-flex flex-row justify-content-between">
                    <span className="username">
                        {answer.user.firstName + " " + answer.user.lastName}
                        <span className="text-muted pull-right">{t("chat.updated", { date: new Date(answer.updatedAt) })}</span>
                    </span>
                    <div className="d-flex flex-row align-items-baseline">
                        <i className={`${upClass} fa-thumbs-up`} onClick={() => onVote(1)}></i>
                        <p className="mx-1">{voteSum}</p>
                        <i className={`${downClass} fa-thumbs-down `} onClick={() => onVote(0)}></i>
                    </div>
                </div>
                {answer.text}
            </div>
        </div>
    )
}

export default Answer
