import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import LiveLecturePlayer from './LiveLecturePlayer';
import './Video.css';

/* Card the shows the YouTube video player in a responsive way. */
const LiveLectureVideoCard = () => {
    
    const { currentLecture } = useContext(ProfileContext);

    return (
        <Card bg="light" border="light" className="video-card">
            <Card.Body>
                <div className="card-video-container">
                    <LiveLecturePlayer videoUrl={currentLecture.youtubeVideoURL}/>
                </div>
            </Card.Body>
        </Card>
    )
}

export default LiveLectureVideoCard