import React from 'react';
import { useTranslation } from 'react-i18next';
import './Courses.css';

/* Simple card that shows the information about the selected course, such as name, instructor and creation date. */
const CourseDetailCard = ({course}) => {
    const { t } = useTranslation();

    return (
        <>
            { course &&  
                <div className="card-box">
                    <div className="inner">
                        <h3>{course.name}</h3>
                        <p><strong>{`[${course.teacher.firstName} ${course.teacher.lastName}] `}</strong>{t("home.courses.created_on", { date: new Date(course.createdAt) })}</p>
                    </div>
                    <div className="icon">
                        <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                    </div>
                </div>
            }
        </>
    )
}

export default CourseDetailCard
