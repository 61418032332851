import React from 'react';
import { Col } from 'react-bootstrap';
import './Participants.css';

/* Card that show the participant name and surname. It is in a different color depending on the participant is 
the instructor or a student. */
const UserCard = ({user, instructor = false}) => {
    return (
        <Col lg={4} sm={4} className="my-1">
            <div className={`widget ${instructor ? 'yellow-bg' : 'lazur-bg'}`}>
                <span><i className={`fas ${instructor ? 'fa-user-tie' : 'fa-user'} mr-1`}></i>{user.lastName + " " + user.firstName}</span>       
            </div>   
        </Col>
    )
}

export default UserCard
