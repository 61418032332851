import React from 'react';
import { useTranslation } from 'react-i18next';
import "./Presentation.css"

/* It shows the commands to change page and zoom. */
const ReportPDFControlPanel = ({pageNumber, numPages, setPageNumber, scale, setScale}) => {
    const { t } = useTranslation();

    const isFirstPage = pageNumber === 1;
    const isLastPage = pageNumber === numPages;
    const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
    const lastPageClass = isLastPage ? 'disabled' : 'clickable';
    const isMinZoom = scale < 0.6;
    const isMaxZoom = scale >= 2.0;
    const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
    const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';
    
    const goToFirstPage = () => {
        if(!isFirstPage) { 
            setPageNumber(1);
        }
    };

    const goToPreviousPage = () => {
        if(!isFirstPage) { 
            setPageNumber(pageNumber - 1);
        }
    }

    const goToNextPage = () => {
        if(!isLastPage) {
            setPageNumber(pageNumber + 1);
        }
    }

    const goToLastPage = () => {
        if(!isLastPage) {
            setPageNumber(numPages);
        }
    }

    const onPageChange = (e) => {
        setPageNumber(Number(e.target.value));
    }

    const zoomOut = () => {
        if(!isMinZoom) setScale(scale - 0.1);
    }

    const zoomIn = () => {
        if(!isMaxZoom) setScale(scale + 0.1);
    }

    return (
        <div className="control-panel d-flex align-items-baseline justify-content-between">
            <div className="d-flex justify-content-between align-items-baseline">
                <i className={`fas fa-fast-backward mx-2 ${firstPageClass}`} onClick={goToFirstPage}></i>
                <i className={`fas fa-backward mx-2 ${firstPageClass}`} onClick={goToPreviousPage}></i>
                <span>
                    {t("presentation.page")} <input name="pageNumber" onChange={onPageChange} type="number" value={pageNumber} min={1} max={numPages || 1} className="p-0 pl-1 mx-2" />
                    {t("presentation.of")} {numPages}
                </span>
                <i className={`fas fa-forward mx-2 ${lastPageClass}`} onClick={goToNextPage}></i>
                <i className={`fas fa-fast-forward mx-2 ${lastPageClass}`} onClick={goToLastPage}></i>
            </div>
            <div className="d-flex justify-content-between align-items-baseline">
                <i className={`fas fa-search-minus mx-2 ${zoomOutClass}`} onClick={zoomOut}></i>
                <span>{(scale * 100).toFixed()}%</span>
                <i className={`fas fa-search-plus mx-2 ${zoomInClass}`} onClick={zoomIn}></i>
            </div>
        </div>
    )
}

export default ReportPDFControlPanel
