import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Nav, Row, Col } from 'react-bootstrap';
import LectureDoughnutChart from '../../Feedbacks/LectureDoughnutChart';
import LectureLineChart from '../../Feedbacks/LectureLineChart';
import UnderstandingWarning from '../../Feedbacks/UnderstandingWarning';
import RecapLineChart from '../../Feedbacks/RecapLineChart';
import { SocketContext } from '../../../context/SocketContext';
import '../Dashboards.css';

/* Feedback dashboard showing a doughnut chart an a line chart. Pressing a button it is also possible to see a chart that 
shows the recap until a certain moment in the lecture. */
const InstructorFeedbackDashboard = () => {
    const { socket } = useContext(SocketContext);
    const [showRecap, setShowRecap] = useState(false);
    const [understandingData, setUnderstandingData] = useState([0, 0, 0]);
    const [fiveMinData, setFiveMinData] = useState([...Array(301)].map(e => 101));
    const { t } = useTranslation();

    const handleUnderstandingChange = useCallback((understanding) => {
        setUnderstandingData(understanding);
    }, []);

    useEffect(() => {
        socket.on('understandingChange', understanding => {
            handleUnderstandingChange(understanding); 
        });

        return () => {
            socket.off('understandingChange', understanding => {
                handleUnderstandingChange(understanding); 
            });
        }
    }, [handleUnderstandingChange, socket]);

    return (
        <Card bg="light" border="light" className="feedback-card">
            <Card.Header className="p-0">
                <Nav className="d-flex flex-md-row justify-content-between tab-title single">
                    <div data-toggle="tab" data-target="#feedbackTab" className="active d-flex align-items-center justify-content-center">
                        {t("dashboard.feedback")}
                        <Button variant="primary" className="feedback-btn" onClick={() => setShowRecap(showRecap => !showRecap)}>
                            {showRecap ?
                            <><i className="fas fa-undo"></i> {t("dashboard.back")}</>
                            :
                            <><i className="fas fa-chart-line"></i> {t("dashboard.recap")}</>}
                        </Button>
                    </div>
                </Nav>
            </Card.Header>
            <Card.Body className="p-1">
                <div className="tab-content h-100">
                    <div className="tab-pane h-100 active" id="feedbackTab">
                        <div className="chart-container h-100 w-100">
                            {showRecap ?
                            <Row className="h-100">
                                <Col md={12} className="h-100">
                                    <RecapLineChart />
                                </Col>   
                            </Row>
                            :
                            <Row className="h-100 overflow-hidden text-center">
                                <Col md={5} className="h-100 my-1">
                                    <UnderstandingWarning understandingData={understandingData} />
                                    <LectureDoughnutChart understandingData={understandingData} />
                                </Col>
                                <Col md={7} className="h-100 p-0">
                                    <LectureLineChart setFiveMinData={setFiveMinData} fiveMinData={fiveMinData} />
                                </Col>
                            </Row>}
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default InstructorFeedbackDashboard
