import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Modal, Button, Form, Row, Col, Container, Spinner } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import YouTubePlayer from '../Video/YouTubePlayer';
import moment from 'moment';
import getVideoId from 'get-video-id';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Switch from 'react-switch';
import axios from '../../axiosInstance';
import './Lectures.css';

/* Modal that permits to update the lecture information such as name, timetable, presentation path and YouTube video url.*/
const UpdateLectureModal = ({ lecture, showUpdateModal, setShowUpdateModal }) => {
    const [name, setName] = useState(lecture.name);
    const [scheduledStartTime, setScheduledStartTime] = useState(moment(lecture.scheduledStartTime).format("YYYY-MM-DDTHH:mm"));
    const [scheduledEndTime, setScheduledEndTime] = useState(moment(lecture.scheduledEndTime).format("YYYY-MM-DDTHH:mm"));
    const [youtubeVideoURL, setYouTubeVideoURL] = useState("https://youtu.be/" + lecture.youtubeVideoURL);
    const [fileData, setFileData] = useState(null);
    const [showPlayer, setShowPlayer] = useState(false);
    const [mooc, setMooc] = useState(lecture.mooc);
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [loading, setLoading] = useState(false);

    const { currentCourse, updateLecture, removePresentation } = useContext(ProfileContext);
    const { t } = useTranslation();

    const loginMethod = localStorage.getItem('loginMethod');

    const isFinished = () => {
        const now = moment();
        return now.isAfter(lecture.scheduledEndTime);
    }

    const fetchLiveUrl = () => {
        setLoading(true);
        axios.get('/lectures/liveBroadcasts')
            .then(res => res.data)
            .then(video => {
                if(video) {
                    const videoUrl = `https://youtu.be/${video.id}`;
                    setYouTubeVideoURL(videoUrl);
                    setShowPlayer(true);
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        if(loginMethod === 'google' && showUpdateModal) {
            axios.get('/lectures/channels')
                .then(res => res.data)
                .then(channels => {
                    console.log(channels)
                    if(channels && channels.length > 0) {
                        setChannels(channels);
                        setSelectedChannel(channels[0]);
                    }
                });
        }
    }, [showUpdateModal, loginMethod]);

    const onSubmit = (e) => {
        e.preventDefault();
        //TODO add checks on datetime and course that must exist
        const courseUUID = currentCourse.courseUUID;
        //Get video id
        const videoId = getVideoId(youtubeVideoURL).id;  
        //Send the request to create a new lecture
        updateLecture({lectureUUID: lecture.lectureUUID, name, scheduledStartTime, scheduledEndTime, courseUUID, youtubeVideoURL: videoId, fileData, mooc: mooc});
        const form = document.getElementById('updateLectureForm');
        form.reset();
        setShowUpdateModal(false);
    }
    
    const onDeletePresentation = () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: t("swal.title"),
            text: t("swal.delete_text"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("swal.confirm")
          }).then((result) => {
            if (result.isConfirmed) {
                removePresentation(lecture);
                MySwal.fire(
                    t("swal.deleted"),
                    t("swal.presentation_deleted"),
                    'success'
                );
            }
        })
    }

    return (
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t("home.update_lecture")}</Modal.Title>
            </Modal.Header>

            <Container>
                <Row>
                    <Col>
                        <Form id="updateLectureForm" onSubmit={onSubmit}>
                            <Modal.Body>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.name")}</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter name" maxLength={99} defaultValue={lecture.name} onChange={(e) => setName(e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.start_time")}</Form.Label>
                                    {isFinished() ? <Form.Control disabled type="datetime-local" defaultValue={moment(lecture.scheduledStartTime).format("YYYY-MM-DDTHH:mm")} onChange={(e) => setScheduledStartTime(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))}/>
                                    : <Form.Control required type="datetime-local" defaultValue={moment(lecture.scheduledStartTime).format("YYYY-MM-DDTHH:mm")} onChange={(e) => setScheduledStartTime(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))}/>}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.end_time")}</Form.Label>
                                    {isFinished() ? <Form.Control disabled type="datetime-local" min={scheduledStartTime} defaultValue={moment(lecture.scheduledEndTime).format("YYYY-MM-DDTHH:mm")} onChange={(e) => setScheduledEndTime(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))}/>
                                    : <Form.Control required type="datetime-local" min={scheduledStartTime} defaultValue={moment(lecture.scheduledEndTime).format("YYYY-MM-DDTHH:mm")} onChange={(e) => setScheduledEndTime(moment(e.target.value).format("YYYY-MM-DDTHH:mm"))}/>}
                                </Form.Group>
                                {loginMethod === 'google' && <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.channel")}</Form.Label>
                                    <Form.Select defaultValue={channels[0]?.channelId} onChange={(e) => setSelectedChannel(e.target.value)}>
                                        {channels.map((channel) => 
                                        (<option key={channel.channelId} value={channel.channelId}>{channel.name}</option>))}
                                    </Form.Select>
                                </Form.Group>}
                                {!isFinished() && <div className="d-flex justify-content-center">
                                    <a href={`https://studio.youtube.com/channel/${selectedChannel}/livestreaming/webcam`} target="_blank" rel="noreferrer">
                                        <Button variant="danger" className="mb-2">
                                            <i className="fab fa-youtube"></i> {t("lectures.start_live")}
                                        </Button>
                                    </a>
                                </div>}
                                <div className="d-flex justify-content-between align-items-center">
                                    {loginMethod === 'google' && !isFinished() &&  <Button variant="success" className="mr-3" onClick={fetchLiveUrl}>
                                        {loading ? <Spinner animation="border" variant="light" /> : 'Load video'}
                                    </Button>}
                                    <Form.Group className="mb-3 w-100">
                                        <Form.Label>{t("lectures.video_url")}</Form.Label>
                                        {isFinished() ? <Form.Control disabled type="text" value={youtubeVideoURL}/>
                                        : <Form.Control type="text" maxLength={499} value={youtubeVideoURL} onChange={(e) => setYouTubeVideoURL(e.target.value)}/>}
                                    </Form.Group>
                                </div>
                                <div className="d-flex justify-content-center mb-3">
                                    <Button variant="primary" onClick={() => setShowPlayer(!showPlayer)}>
                                        <i className="fas fa-video"></i> {showPlayer ? t("lectures.hide_video") : t("lectures.test_video") }
                                    </Button>
                                </div>
                                {showPlayer && <div className="video-container mb-3 px-5">
                                    <YouTubePlayer videoUrl={getVideoId(youtubeVideoURL).id}/>
                                </div>}
                                {!isFinished() && <Form.Group className="mb-3">
                                    <Form.Label>{t("lectures.presentation")}</Form.Label>
                                    {lecture.presentationPath && <Alert variant="info">{t("lectures.presentation_loaded")}</Alert>}
                                    <div className="d-flex flex-row">
                                        <Form.Control type="file" accept="application/pdf" onChange={(e) => setFileData(e.target.files[0])}/>
                                        {lecture.presentationPath && <Button variant="danger" onClick={onDeletePresentation}><i className="fas fa-trash"></i></Button>}
                                    </div>
                                </Form.Group>}
                                <Form.Group className="d-flex flex-row justify-content-start mb-3">
                                    <Form.Label>{t("lectures.mooc")}</Form.Label>
                                    <Switch onChange={value => setMooc(value)} checked={mooc} className="react-switch ml-2" />
                                </Form.Group>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="primary" type="submit">
                                    {t("lectures.submit")}
                                </Button>
                                <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
                                    {t("lectures.close")}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Modal>
    )
}

export default UpdateLectureModal
