import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';
import { ProfileContext } from '../../context/ProfileContext';
import { SocketContext } from '../../context/SocketContext';
import { AuthContext } from '../../context/AuthContext';
import { VideoContext } from '../../context/VideoContext';
import ChatMessage from './ChatMessage';
import './Chat.css';

/* Box that contains all the questions associated to the lecture. It is possible to insert a new question 
also in the report page. */
const ReportQuestionBox = ({ setNewMessagesNumber }) => {
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const { socket } = useContext(SocketContext);
    const { player } = useContext(VideoContext);
    const { t } = useTranslation();

    const [question, setQuestion] = useState('');
    const [questionsList, setQuestionsList] = useState([]);

    const onQuestionSend = (e) => {
        e.preventDefault();
        
        if(question.length > 0) {
            const videoTime = parseInt(player.getCurrentTime());
            socket.emit('question', {
                question: question,
                user: currentUser,
                room: currentLecture.lectureUUID,
                videoTime: videoTime
            });
            
            const form = document.getElementById('chat-form');
            form.reset();
        }
    }

    const handleNewMessage = useCallback(message => {
        //Push message into messages list
        setQuestionsList(messagesList => [...messagesList, message]);
        setNewMessagesNumber(n => n + 1); //Increment number of new messages

        const chatMessages = document.querySelector('.chat-messages');
        //Scroll down
        if(chatMessages) chatMessages.scrollTop = chatMessages.scrollHeight;
    }, [setNewMessagesNumber]); 
    
    const handleQuestionsResume = useCallback((chatMessages) => {
        //Push messages into messages list
        setQuestionsList(chatMessages);
        //Set messages number
        setNewMessagesNumber(chatMessages.length);

        //Scroll down
        const chatMessagesContainer = document.querySelector('.chat-messages');
        if(chatMessagesContainer) chatMessagesContainer.scrollTop = chatMessagesContainer.scroll;
    }, [setNewMessagesNumber]);
    
    const handleMessageUpdate = useCallback((chatMessage) => {
        setQuestionsList( oldMessagesList => 
            oldMessagesList.map(message => 
                message.messageUUID === chatMessage.messageUUID
                ? chatMessage
                : message )
        );
    }, [])

    useEffect(() => {
        socket.on('newChatMessage', (message) => {
            handleNewMessage(message)
        });

        socket.on('questionsResume', (questions) => {
            handleQuestionsResume(questions);
        });

        socket.on('messageUpdate', (message) => {
            handleMessageUpdate(message);
        });

        return () => {
            setQuestionsList([]);

            socket.off('newChatMessage', (message) =>
                handleNewMessage(message));
            
            socket.off('questionsResume', (questions) =>
            handleQuestionsResume(questions));
                
            socket.off('messageUpdate', (message) => {
                handleMessageUpdate(message);});
        }
    }, [socket, handleNewMessage, handleMessageUpdate, handleQuestionsResume]);

    return (
        <div className="d-flex flex-column h-100">
            <div className="chat-messages">
                {questionsList.map((chatMessage, index) => 
                    <ChatMessage key={chatMessage.messageUUID} chatMessage={chatMessage}/>)}
            </div>
            <Form id="chat-form" onSubmit={onQuestionSend} className="d-flex flex-row align-items-center">
                <Form.Control id="msg" type="text" placeholder="Enter question" autoComplete="off" maxLength={255} required onChange={(e) => setQuestion(e.target.value)}/>
                <Button variant="warning" type="submit" 
                data-bs-toggle="tooltip" data-bs-placement="top" title={t("tooltip.post_question")}>
                    <i className="fas fa-question"></i>
                </Button>
            </Form>
        </div>
    )
}

export default ReportQuestionBox
