import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../context/AuthContext'
import { Button, Container, Row, Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import imgSrc from '../assets/images/index.png'
import RegistrationForm from '../components/Auth/RegistrationForm'
import LoginForm from '../components/Auth/LoginForm'
import AppNavbar from '../components/Navbars/AppNavbar';
import './Pages.css'

const IndexPage = () => {
    const { loginUser } = useContext(AuthContext); //to save user in the context
    const history = useHistory(); //to redirect
    const [showLogin, setShowLogin] = useState(true);
    const [successMessageReg, setSuccessMessageReg] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        //Try to get the user from the local storage, if the user is present it can be still logged
        let user = localStorage.getItem('user');
        if(user) {
            user = JSON.parse(user);
            loginUser(user); //Set user in the store
            history.push('/home'); //Redirect to the home page
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    return (
        <Container fluid className="banner">
            <Row>
                <AppNavbar />
            </Row>
            <Row className="content">
                <Col md={6} sm={12} className="info my-5">
                    <h1 className="app-title">{t('index.title')}</h1>
                    <p className="app-subtitle">{t('index.subtitle')}</p>
                    <Image className="index-img" src={imgSrc} fluid />
                    <Link to="/about">
                        <Button variant="light" className="border border-primary rounded" size="md">
                            {t('index.about')}
                        </Button>
                    </Link>
                </Col>
                
                <Col md={6} sm={12}>
                    {showLogin ?
                    <LoginForm setShowLogin={setShowLogin} successMessageReg={successMessageReg} />
                    :
                    <RegistrationForm setShowLogin={setShowLogin} setSuccessMessageReg={setSuccessMessageReg} />
                    }
                </Col>
                
            </Row>
        </Container>
    )
}

export default IndexPage
