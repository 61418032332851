import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import ReportPDFControlPanel from '../../Presentation/ReportPDFControlPanel';
import PDFViewer from '../../Presentation/PDFViewer';
import { ProfileContext } from '../../../context/ProfileContext';
import '../Dashboards.css';

/* Dashboard that shows the presentation viewer and the pdf control panel. */
const ReportPresentationDashboard = ({pageNumber, setPageNumber}) => {
    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(0.7);
    
    const { currentLecture } = useContext(ProfileContext);
    const { t } = useTranslation();

    return (
        <Card bg="light" border="light">
            <Card.Body className="pdf-container d-flex justify-content-center big-card-content-scrollable">
                {currentLecture.presentationPath ? 
                <PDFViewer presentationPath={currentLecture.presentationPath} setNumPages={setNumPages} pageNumber={pageNumber} scale={scale}/>
                :
                <h5>{t("dashboard.no_presentation")}</h5>
                }    
            </Card.Body>
            <Card.Footer className="p-0">
                <div className="tab-title-reverse">
                    <ReportPDFControlPanel pageNumber={pageNumber} setPageNumber={setPageNumber} numPages={numPages} scale={scale} setScale={setScale} />
                </div>
            </Card.Footer>
        </Card>
    )
}

export default ReportPresentationDashboard