import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Nav, ProgressBar } from 'react-bootstrap';
import ReportLineChart from '../../Feedbacks/ReportLineChart';
import { ProfileContext } from '../../../context/ProfileContext';
import axios from '../../../axiosInstance';
import { VideoContext } from '../../../context/VideoContext';
import { SocketContext } from '../../../context/SocketContext';
import '../Dashboards.css';

/* Dashboard that show a progress bar indicating the understaning hystory of the current user in the lecture and
a line chart that shows the understaning % trend of the students partecipanting to the lecture. */
const ReportFeedbackDashboard = () => {
    const { currentLecture } = useContext(ProfileContext);
    const [understandings, setUnderstandings] = useState([]);
    const { duration } = useContext(VideoContext);
    const [disabled, setDisabled] = useState(false);
    const { socket } = useContext(SocketContext);
    const { t } = useTranslation();

    const handleEvent = useCallback(({understandingActive}) => {
        setDisabled(!understandingActive);
    }, []);

    useEffect(() => {
        axios.get('/lectures/studentUnderstandings/' + currentLecture.lectureUUID)
          .then(res => {
            //Map the understandings on the data
            setUnderstandings(res.data.map(und => ({
              videoSeconds: und.videoSeconds,
              value: und.value,
              timePercentage: und.videoSeconds / duration * 100 
            })));            
        });

        socket.on("newFunctionalityStatus", (status) => {
            handleEvent(status);
        });

        return () => {
            setUnderstandings([]);

            socket.off("newFunctionalityStatus", (status) => {
                handleEvent(status);
            });
        }
    }, [currentLecture.lectureUUID, duration, handleEvent, socket]);

    const formatSeconds = (seconds) => {
        return Math.floor(seconds / 60) + ":" + (seconds % 60 < 10 ? "0" + Math.floor(seconds % 60) : Math.floor(seconds % 60))
    }

    return (
        <Card bg="light" border="light" className="feedback-card">
            <Card.Header className="p-0">
                <Nav className="d-flex flex-md-row justify-content-between tab-title single">
                    <div data-toggle="tab" data-target="#feedbackTab" className="active">{t("dashboard.feedback")}</div>
                </Nav>
            </Card.Header>
            <Card.Body>
                <div className="tab-content h-100">
                    <div className="tab-pane h-100 active" id="feedbackTab">
                        <div className="d-flex flex-column h-100">
                            <ProgressBar className="feedback-pb">
                                {//If there is at least one understanding
                                understandings.length > 0 ?
                                <ProgressBar variant="info" now={understandings[0].timePercentage}
                                    data-bs-toggle="tooltip" data-bs-placement="top" title={t("dashboard.not_present_seconds", { minutes: formatSeconds(0), seconds: formatSeconds(understandings[0].videoSeconds) })} />
                                : //else
                                <ProgressBar variant="info" now={100}
                                    data-bs-toggle="tooltip" data-bs-placement="top" title={t("dashboard.not_present_seconds", { minutes: formatSeconds(0), seconds: formatSeconds(duration) })}/>
                                }
                                {understandings.map((el, index, elements) => {
                                    //Select progress bar color
                                    let variant = "info";
                                    let label = t("dashboard.not_present");
                                    if(el.value === 0) { variant = "danger"; label = t("dashboard.unclear"); 
                                    }else if(el.value === 1) { variant = "warning"; label = t("dashboard.middle_clear");
                                    }else if(el.value === 2) { variant = "success"; label = t("dashboard.clear"); }
                                    let now = 0; //Now is the pb length in %, if 20 it occupies the 20% of the bar
                                    let nextElSeconds = "" //The ending seconds of the bar
                                    if(elements.length !== index + 1) { //If it is not the last element
                                        now = elements[index + 1].timePercentage - el.timePercentage; 
                                        nextElSeconds = elements[index + 1].videoSeconds;
                                    }else{ //If it is the last element
                                        now = 100 - el.timePercentage;
                                        nextElSeconds = duration;
                                    }
                                    const tooltipText = t("tooltip.progress_bar", { label: label, from: formatSeconds(el.videoSeconds), to: formatSeconds(nextElSeconds) });
                                    return <ProgressBar key={index} variant={variant} now={now}
                                    data-bs-toggle="tooltip" data-bs-placement="top" title={tooltipText}/>
                                })}
                            </ProgressBar>
                            {disabled ?
                            <div className="h-100">
                                <div className="disabled-card"></div>
                                <div className="disabled-card-text">
                                    <h5>{t("dashboard.feedback_disabled")}</h5>
                                </div>
                            </div>
                            :
                            <div className="chart-container h-100 w-100">
                                <ReportLineChart />
                            </div>}
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ReportFeedbackDashboard