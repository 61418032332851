import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import spinner from '../../assets/images/spinner.gif';

/* Doughnut chart that shown the number of current student connected to the lecture that is understanding 0, 1 and 2. */
const LectureDoughnutChart = ({ understandingData }) => {
    const { t } = useTranslation();

    const [isWaiting, setIsWaiting] = useState(true);

    ChartJS.register(ArcElement, Tooltip, Legend);

    useEffect(() => {
        const waiting = understandingData[0] + understandingData[1] + understandingData[2] === 0;
        setIsWaiting(waiting);
    }, [understandingData])

    const data = {
        labels: ['Not clear', 'Middle clear', 'Clear'],
        datasets: [{
            label: '# of Votes',
            data: understandingData,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgb(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [                
                'rgba(255, 99, 132, 1)',
                'rgb(255, 205, 86, 1)',
                'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1,
        }],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            }
        }
    };

    return (
        <>
        {isWaiting ?
        <div className="d-flex flex-column align-items-center">
            <p className="text-uppercase text-primary font-weight-bold">{t("feedback.waiting")}</p>
            <img width="200px" height="200px" src={spinner} alt="" />
        </div>
        :
        <div className="h-100">
            <Doughnut className="blink" data={data} height="100%" options={options} />
        </div>
        }
        </>
    )
}

export default LectureDoughnutChart
