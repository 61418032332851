import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { setupInterceptors } from './axiosInstance'

const InjectAxiosInterceptors = () => {
    const history = useHistory();

    useEffect(() => {
        setupInterceptors(history);
    }, [history]);
    
    //not render anything
    return null;
}

export default InjectAxiosInterceptors
