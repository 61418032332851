import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Row, Col, Image } from 'react-bootstrap';
import AppNavbar from '../components/Navbars/AppNavbar';
import { Link } from 'react-router-dom';
import aboutImg from '../assets/images/about.png';
import './Pages.css';

const AboutPage = () => {
    const { t } = useTranslation();
    
    return (
        <Container fluid className="banner">
            <Row>
                <AppNavbar />
            </Row>
            <Row className="content">
                <Col md={8} sm={12} className="about-left">
                    <h2>{t("about.question1")}</h2>
                    <p>{t("about.answer1")}</p>
                    <h2>{t("about.question2")}</h2>
                    <p>{t("about.answer2")}</p>
                    <h2>{t("about.question3")}</h2>
                    <p>{t("about.answer3")}</p>
                    <h2>{t("about.question4")}</h2>
                    <p>{t("about.answer4")}</p>
                    <h2>{t("about.question5")}</h2>
                    <Link to="/">
                        <Button variant="light" className="border border-primary rounded" size="md">
                            {t("about.join_us")}
                        </Button>
                    </Link>
                </Col>
                
                <Col md={4} sm={12} className="about-right">
                    <Image className="about-img" src={aboutImg} fluid />
                </Col>
            </Row> 
        </Container>
    )
}

export default AboutPage
