import React, { useContext, useEffect, useState, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, Card, Badge } from 'react-bootstrap';
import TextEditor from '../../Notes/TextEditor';
import ReportQuestionBox from '../../Chat/ReportQuestionBox';
import { ProfileContext } from '../../../context/ProfileContext';
import { SocketContext } from '../../../context/SocketContext';
import { AuthContext } from '../../../context/AuthContext';
import '../Dashboards.css';

/* Dashboard that shows the lecture questions and the notes associated to the lecture. */
const ReportCommunicationDashboard = ({ pageNumber }) => {
    const { socket } = useContext(SocketContext);
    const { currentUser } = useContext(AuthContext);
    const { currentLecture } = useContext(ProfileContext);
    const [chatDisabled, setChatDisabled] = useState(false);
    const [notesDisabled, setNotesDisabled] = useState(false);
    const [newMessagesNumber, setNewMessagesNumber] = useState(0);
    const [newNotesNumber, setNewNotesNumber] = useState(0);
    const { t } = useTranslation();

    const handleEvent = useCallback(({chatActive, notesActive}) => {
        setChatDisabled(!chatActive);
        setNotesDisabled(!notesActive);
    }, []);
    
    useEffect(() => {
        socket.on("newFunctionalityStatus", (status) => {
            handleEvent(status);
        });

        return () => {
            setChatDisabled({});
            setNotesDisabled({});

            socket.off("newFunctionalityStatus", (status) => {
                handleEvent(status);
            });
        }
    }, [socket, handleEvent, currentLecture.lectureUUID, currentUser]);
    
    return (
        <Card bg="light" border="light" className="communication-card">
            <Card.Header className="p-0">
                <Nav className="d-flex flex-md-row justify-content-between tab-title double">
                    <div data-toggle="tab" data-target="#chatTab" className="active"> 
                        {t("dashboard.questions")} 
                        {newMessagesNumber > 0 && <Badge pill bg="success" className="ml-1">{newMessagesNumber}</Badge>}
                    </div>
                    <div data-toggle="tab" data-target="#editorTab"> 
                        {t("dashboard.notes")}
                        {newNotesNumber > 0 && <Badge pill bg="primary" className="ml-1">{newNotesNumber}</Badge>}
                    </div>
                </Nav>       
            </Card.Header>
            <Card.Body className="card-container">
                <div className="tab-content h-100">
                    <div className="tab-pane active h-100" id="chatTab">
                        {chatDisabled ?
                        <div className="h-100">
                            <div className="disabled-card"></div>
                            <div className="disabled-card-text">
                                <h5>{t("dashboard.questions_disabled")}</h5>
                            </div>
                        </div>
                        :
                        <ReportQuestionBox setNewMessagesNumber={setNewMessagesNumber} />}
                    </div>
                    <div className="tab-pane h-100" id="editorTab">
                        {notesDisabled ?
                        <div className="h-100">
                            <div className="disabled-card"></div>
                            <div className="disabled-card-text">
                                <h5>{t("dashboard.notes_disabled")}</h5>
                            </div>
                        </div>
                        :
                        <TextEditor pageNumber={pageNumber} setNewNotesNumber={setNewNotesNumber}/> }
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ReportCommunicationDashboard
