/* eslint-disable import/no-anonymous-default-export */
export default (state, action) => {
    switch (action.type) {
        case 'GET_COURSES':
            return {
                ...state,
                courses: action.payload
            }
        case 'GET_FOLLOWED_COURSES':
            return {
                ...state,
                followedCourses: action.payload
            }
        case 'CREATE_COURSE':
            return {
                ...state,
                courses: [...state.courses, action.payload]
            }
        case 'DELETE_COURSE':
            return {
                ...state,
                courses: state.courses.filter(c => c.courseUUID !== action.payload)
            }
        case 'UNSUBSCRIBE_COURSE':
            return {
                ...state,
                followedCourses: state.followedCourses.filter(c => c.courseUUID !== action.payload)
            }
        case 'SUBSCRIBE_COURSE':
            return {
                ...state,
                followedCourses: [...state.followedCourses, action.payload]
            }
        case 'SELECT_COURSE':
            return {
                ...state,
                currentCourse: action.payload
            }
        case 'SELECT_FOLLOWED_COURSE':
            return {
                ...state,
                currentFollowedCourse: action.payload
            }
        case 'GET_LECTURES':
            return {
                ...state,
                lectures: action.payload
            }
        case 'GET_FOLLOWED_LECTURES':
            return {
                ...state,
                followedLectures: action.payload
            }
        case 'CREATE_LECTURE':
            return {
                ...state,
                lectures: [...state.lectures, action.payload]
            }
        case 'UPDATE_LECTURE':
            return {
                ...state,
                lectures: [...state.lectures.filter(l => l.lectureUUID !== action.payload.lectureUUID), action.payload]
            }
        case 'SELECT_LECTURE':
            return {
                ...state,
                currentLecture: action.payload
            }
        case 'DELETE_LECTURE': 
            return {
                ...state,
                lectures: state.lectures.filter(l => l.lectureUUID !== action.payload)
            }
        case 'ERROR': {
            return {
                ...state,
                error: action.payload
            }
        }
        default: 
            return state;
    }
}