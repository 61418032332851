import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Form } from 'react-bootstrap';
import axios from '../../axiosInstance';
import { AuthContext } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

/* A user can login inserting email and password into this form. If login success, he is redirected in the
home page, otherwise an error message is displayed. */
const LoginForm = ({ successMessageReg, setShowLogin }) => {
    const { loginUser } = useContext(AuthContext); //to save user in the context
    const { t } = useTranslation();
    const history = useHistory(); //to redirect

    //Login form fields
    const [loginMessage, setLoginMessage] = useState('');
    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    /* const handleGoogleLoginSuccess = (googleData) => {
        //Note that profile info such as email, first name, last name, ecc are into googleData.profileObj
        //The token id is in googleData.tokenId
        axios.post('auth/googleLogin', {
            token: googleData.tokenId,
            accessToken: googleData.accessToken
        }).then(response => {
            //Login success
            if(response.data.loginStatus === 'ok') {
                setLoginMessage(''); //Reset message
                const user = response.data.user;
                loginUser(user); //Register user in the context
                localStorage.setItem('user', JSON.stringify(user)); //Push user in the storage
                localStorage.setItem('loginMethod', 'google'); //Set login method
                history.push('/home'); //Redirect to home page
            }else{ //Login fail
                //Set error messages.
                const message = response.data.message;
                setLoginMessage(message);
            }
        });
    } */

    const handleGoogleLoginFailure = () => {
        setLoginMessage(t('index.login.google_login_fail'));
    }

    const onSubmitLogin = (e) => {
        e.preventDefault();      
        //Axios login request
        axios.post('auth/login', { 
            email: emailLogin,
            password: passwordLogin
        }).then((response) => {
            //Login success
            if(response.data.loginStatus === 'ok') {
                setLoginMessage(''); //Reset message
                const user = response.data.user;
                loginUser(user); //Register user in the context
                localStorage.setItem('user', JSON.stringify(user)); //Push user in the storage
                localStorage.setItem('loginMethod', 'normal'); //Set login method
                history.push('/home'); //Redirect to home page
            }else{ //Login fail
                //Set error messages.
                const message = response.data.message;
                setLoginMessage(message);
            }
        }).catch((err) => {
            console.log(err);
            setLoginMessage(t('index.login.login_fail'));
        });
    }

    return (
    <Form className="index-form" onSubmit={onSubmitLogin}>
        <h2 className="text-white">
            <i className="fas fa-sign-in-alt"></i> {t('index.login.title')} 
        </h2>
        { loginMessage && <Alert variant="warning">{loginMessage}</Alert> }
        {successMessageReg && <Alert variant="success">{successMessageReg}</Alert>}
        <div className="input-field">
            <i className="fas fa-user"></i>
            <Form.Control required type="email" placeholder={t('index.login.email_placeholder')} onChange={(e) => setEmailLogin(e.target.value)} />
        </div>
        <div className="input-field">
            <i className="fas fa-lock"></i>
            <Form.Control required type={showPassword ? "text" : "password"} placeholder={t('index.login.password_placeholder')} onChange={(e) => setPasswordLogin(e.target.value)} />
            <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} show-password`} onClick={() => setShowPassword(!showPassword)} />
        </div>
        <Button type="submit" variant="light" className="border border-primary rounded font-weight-bold text-primary">
            {t('index.login.normal_login')}
        </Button>
        {/* <h5 className="my-2 text-light">{t('index.login.or')}</h5>
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Log in with Google"
            render={renderProps => (
                <Button variant="light" className="border-primary rounded font-weight-bold text-primary" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    <i className="fab fa-google"></i> {t('index.login.google_login')}
                </Button>
              )}
            onSuccess={handleGoogleLoginSuccess}
            onFailure={handleGoogleLoginFailure}
            cookiePolicy={'single_host_origin'}
            scope='https://www.googleapis.com/auth/youtube.readonly'
        /> */}
        <h5 className="my-2 text-light">{t('index.signup.question')}</h5>
        <Button variant="light" className="special-button border border-primary rounded font-weight-bold text-primary" onClick={() => setShowLogin(false)}>
            {t('index.signup.answer')}
        </Button>
    </Form>
  );
};

export default LoginForm;